import * as firebase from 'firebase/app';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import profile from '../../images/profile.png';
import AccountModal from './AccountModal';
import User from './User';

import './Header.scss';

interface Props {
  logOut: () => void;
  user: firebase.User | null;
}

interface State {
  showSignin: boolean;
}

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSignin: false,
    };
  }

  public render() {
    const { logOut, user } = this.props;
    const { showSignin } = this.state;

    return (
      <Container className="Header mt-3 mb-5">
        <Row>
          <Col xs={5}>
            <Link to="/">
              <img src={profile} alt="Profile" />
            </Link>
          </Col>
          <Col className="text-right text-white" xs={7}>
            {user ? (
              <User logOut={logOut} user={user} />
            ) : (
              <div className="d-flex flex-column h-100 justify-content-center">
                <a className="text-white" href="#" onClick={this.signIn}>
                  Sign in
                </a>
              </div>
            )}
          </Col>
        </Row>
        <AccountModal close={this.close} isOpen={showSignin} />
      </Container>
    );
  }

  private signIn = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ showSignin: true });
  };

  private close = () => {
    this.setState({ showSignin: false });
  };
}

export default Header;
