import USPSSync, { Address } from 'usps-webtools';

type AddressField = keyof Address;

export default class USPS {
  public static async verify(address: Address): Promise<Address> {
    return new Promise((res, rej) => {
      this.getUSPS().verify(address, (err, address) => {
        if (err) {
          return rej(err);
        }
        res(this.format(address));
      });
    });
  }

  public static async zipCodeLookup(address: Address): Promise<Address> {
    return new Promise((res, rej) => {
      this.getUSPS().zipCodeLookup(address, (err, address) => {
        if (err) {
          return rej(err);
        }
        res(this.format(address));
      });
    });
  }

  public static async cityStateLookup(zip: string): Promise<Address> {
    return new Promise((res, rej) => {
      this.getUSPS().cityStateLookup(zip, (err, address) => {
        if (err) {
          return rej(err);
        }
        res(this.format(address));
      });
    });
  }

  public static getErrorField(msg: string): AddressField | null {
    msg = msg.toLowerCase();
    if (msg.includes('address')) {
      return 'street1';
    }

    if (msg.includes('city')) {
      return 'city';
    }

    if (msg.includes('state')) {
      return 'state';
    }

    if (msg.includes('zip')) {
      return 'zip';
    }

    return null;
  }

  private static getUSPS(): USPSSync {
    return new USPSSync({
      server: 'https://secure.shippingapis.com/ShippingAPI.dll',
      userId: '346UVTEA4246',
    });
  }

  private static format(address: Address): Address {
    return Object.assign<Partial<Address>, Address>(
      {},
      Object.entries(address)
        .map<[AddressField, string]>(([k, v]) => [k as AddressField, v as string])
        .reduce<Address>(
          (addr, [k, v]) => ({
            ...addr,
            [k]: this.titleCase(k, v),
          }),
          address
        )
    );
  }

  private static titleCase(field: AddressField, str: string): string {
    if (field === 'zip') {
      return str;
    }

    if (field === 'state') {
      return str.toUpperCase();
    }

    return str
      .split(' ')
      .map((sub) => sub.slice(0, 1).toUpperCase() + sub.slice(1).toLowerCase())
      .join(' ');
  }
}
