import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  email: string;
  hidden: boolean;
}

interface State {
  deleteText: string;
  deleted: boolean;
  isOpen: boolean;
}

class Delete extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteText: '',
      deleted: false,
      isOpen: false,
    };
  }

  public componentWillUnmount() {
    if (this.state.deleted) {
      void firebase
        .firestore()
        .collection('users')
        .doc(this.props.email.toLowerCase())
        .delete()
        .then(() => {
          ReactGA.event({ action: 'Deleted all data', category: 'User' });
        })
        .then(() => firebase.auth().signOut())
        .then(() => {
          ReactGA.event({ action: 'Sign out', category: 'User' });
        });
    }
  }

  public render() {
    const { email, hidden } = this.props;
    const { deleteText, deleted, isOpen } = this.state;

    if (deleted) {
      return <Redirect to="/" />;
    }

    if (hidden) {
      return null;
    }

    return (
      <div>
        <Button className="d-block w-100" color="danger" size="lg" onClick={this.toggle}>
          Delete All Data
        </Button>
        <Modal isOpen={isOpen} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Delete all user data?</ModalHeader>
          <ModalBody>
            <p>
              You will remove all data associated with{' '}
              <span className="font-weight-bolder">{email}</span>
            </p>
            <p>
              All of your data will be deleted from our system and you will no longer recieve emails
              from our mailing list.
            </p>
            <p>To delete, please type your email address into the textbox below</p>
            <Form onSubmit={this.deleteData}>
              <Input
                className="mb-2 rounded-0"
                id="delete"
                onChange={this.change}
                type="text"
                value={deleteText}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" disabled={deleteText !== email} onClick={this.deleteData}>
              Delete
            </Button>
            <Button onClick={this.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  private toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private change = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ deleteText: event.target.value });
  };

  private deleteData = (event: React.FormEvent) => {
    event.preventDefault();

    const { email } = this.props;
    const { deleteText } = this.state;

    if (deleteText !== email) {
      return;
    }

    this.setState({ deleted: true });
  };
}

export default Delete;
