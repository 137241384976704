import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Elements } from 'react-stripe-elements';
import { Button, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import stripe from 'stripe';

import MailingList from '../home/section/MailingList';
import subscriptionImg from '../../images/subscription.jpg';
import SubscriptionForm from './SubscriptionForm';

import './Subscribe.scss';

interface State {
  details?: firebase.firestore.DocumentSnapshot;
  isModalOpen: boolean;
  plans?: stripe.ApiList<stripe.Plan>;
  subscriptionCount?: number;
  user: firebase.User | null;
}

class Subscribe extends Component<any, State> {
  private MAX_SUBSCRIPTIONS = 50;

  constructor(props: any) {
    super(props);
    this.state = {
      isModalOpen: false,
      user: firebase.auth().currentUser,
    };
  }

  public componentDidMount() {
    ReactGA.pageview('/subscribe');
    void this.loadData();
  }

  public render() {
    const { details, isModalOpen, plans, subscriptionCount, user } = this.state;

    const soldOut = !!(subscriptionCount && subscriptionCount >= this.MAX_SUBSCRIPTIONS);

    let content;
    if (plans && plans.data.length > 0) {
      const plan = plans.data[0];
      if (plan.amount) {
        content = (
          <Row className="p-4">
            <Col md={5}>
              <img className="subscription-box" src={subscriptionImg} alt="Subscription Box" />
            </Col>
            <Col md={7}>
              <h1>UV & Tea Subscription Box</h1>
              <div className="subscription-text">
                <h3 className="my-4">${(plan.amount / 100).toFixed(2)}</h3>
                <p>
                  The UV & Tea monthly subscription box provides you with a monthly supply of
                  everything you need to protect and rejuvinate your skin, with a variety of
                  products so you can discover what products work best for you. Every box comes
                  with:
                </p>
                <ul>
                  <li>One full size sunscreen</li>
                  <li>One full size lip treatment</li>
                  <li>One full size loose leaf tea</li>
                  <li>Bonus themed items and samples</li>
                </ul>
                <p>
                  Boxes ship out on the first of every month with brand new products for you to try.
                </p>
                <p className="font-weight-bold">
                  Bonus: To help you enjoy our fine loose leaf teas, order now and you will receive
                  a free mug with tea infuser in your first box! An $11 value!
                </p>
              </div>
              <div className="subscription-button text-center">
                {this.renderSubscribeButton(soldOut)}
              </div>
              <Modal centered={true} isOpen={isModalOpen} size="lg" toggle={this.closeModal}>
                <ModalBody>
                  <Elements>
                    <SubscriptionForm details={details} plan={plan} user={user} />
                  </Elements>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        );
      }
    }

    const classNames = ['Subscribe', 'rounded'];
    if (soldOut) {
      classNames.push('disabled');
    }

    return (
      <Container className={classNames.join(' ')}>
        <Helmet>
          <title>UV & Tea - Subscription Box</title>
        </Helmet>
        {content}
      </Container>
    );
  }

  private renderSubscribeButton = (soldOut: boolean) => {
    if (soldOut) {
      return (
        <React.Fragment>
          <h5>Sold Out!</h5>
          <p>
            Demand has been higher than expected and we are unable to fulfill new orders. Please
            sign up for our mailing list to be notified when orders have re-opened!
          </p>
          <MailingList />
        </React.Fragment>
      );
    }

    return (
      <Button className="my-5" color="info" size="lg" onClick={this.openModal}>
        Subscribe Now
      </Button>
    );
  };

  private openModal = () => {
    this.setState({ isModalOpen: true });
  };

  private closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  private loadData = async () => {
    const [plans, subscriptionCount, details] = await Promise.all([
      (async () => {
        const fetchPlans = firebase.functions().httpsCallable('fetchPlans');
        const result = await fetchPlans();
        return result.data as stripe.ApiList<stripe.Plan>;
      })(),
      (async () => {
        const fetchSubscriptionCount = firebase.functions().httpsCallable('fetchSubscriptionCount');
        const result = await fetchSubscriptionCount();
        return result.data as number;
      })(),
      (async () => {
        const user = this.state.user;
        if (!user || !user.email) {
          return;
        }
        const email = user.email;

        return firebase.firestore().collection('users').doc(email.toLowerCase()).get();
      })(),
    ]);

    this.setState({ details, plans, subscriptionCount });
  };
}

export default Subscribe;
