import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, FormText, Input } from 'reactstrap';
import validator from 'validator';

interface Props extends RouteComponentProps {
  close: () => void;
  signin: () => void;
}

interface State extends Fields<string> {
  errors: Fields<string>;
  submitted: boolean;
  touched: Fields<boolean>;
}

interface Fields<T> {
  confirm: T;
  email: T;
  password: T;
}
type Field = keyof Fields<void>;

class Signup extends Component<Props, State> {
  private input: React.RefObject<HTMLInputElement>;

  /* eslint-disable */
  private sortOrder: Fields<number> = {
    email: 1,
    password: 2,
    confirm: 3,
  };
  /* eslint-enable */

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      submitted: false,
      touched: {
        ...this.initializeFields(false),
      },
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  public render() {
    const { confirm, email, password } = this.state;

    return (
      <div className="Signup p-4 text-white">
        <h2 className="mb-3">Sign Up</h2>
        <p className="mb-4">Create an account to manage your details</p>
        <Form onSubmit={this.submit}>
          <FormGroup>
            <Input
              className="mb-3 rounded-0"
              id="email"
              innerRef={this.input}
              invalid={this.isInvalid('email')}
              onChange={this.change('email')}
              placeholder="Email Address"
              type="text"
              valid={this.isValid('email')}
              value={email}
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="mb-3 rounded-0"
              id="password"
              invalid={this.isInvalid('password')}
              onChange={this.change('password')}
              placeholder="Password"
              type="password"
              valid={this.isValid('password')}
              value={password}
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="mb-3 rounded-0"
              id="confirm"
              invalid={this.isInvalid('confirm')}
              onChange={this.change('confirm')}
              placeholder="Confirm Password"
              type="password"
              valid={this.isValid('confirm')}
              value={confirm}
            />
          </FormGroup>
          <FormGroup>
            <FormText className="mt-0 mb-3" color="danger">
              {this.getCurrentError()}
            </FormText>
            <Button
              block={true}
              className="px-5"
              color="info"
              disabled={this.someInvalid()}
              size="lg">
              Create Account
            </Button>
          </FormGroup>
        </Form>
        <p className="text-center">
          <a className="text-white" href="#" onClick={this.signin}>
            Back to sign in
          </a>
        </p>
      </div>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [field]: event.target.value,
      touched: this.initializeFields(true),
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const { close, history } = this.props;
    const { email, password } = this.state;

    try {
      const credential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      await firebase.firestore().collection('users').doc(email.toLowerCase()).set(
        {
          isVerified: false,
          mailingListApproved: false,
        },
        {
          merge: true,
        }
      );
      const url = new URL('/verify', window.location.href).href;
      if (credential.user) {
        await credential.user.sendEmailVerification({ url });
      }
      history.push('/details');
      close();
    } catch (e) {
      const code = (e as firebase.FirebaseError).code;
      const errors = this.initializeFields('');
      if (code === 'auth/weak-password') {
        errors.password = 'Please use a longer password';
      }
      if (code === 'auth/email-already-in-use') {
        errors.email = 'This email is already in use';
      }
      this.setState({
        errors,
      });
      return;
    }
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate fields
    this.getFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    // Make sure password and confirm are the same
    if (this.state.confirm !== this.state.password) {
      errors.confirm = 'Password confirmation must match password';
    }

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'confirm':
        return 'Please confirm your password';
      case 'email':
        return 'Please provide a valid email address';
      case 'password':
        return 'Please provide a password';
      default:
        return '';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      confirm: val,
      email: val,
      password: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };

  private signin = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.signin();
  };
}

export default withRouter(Signup);
