import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './SocialMedia.scss';

import tea from '../../../images/tea-bw-sm.png';

class SocialMedia extends Component {
  public render() {
    const className = [
      'SocialMedia',
      'align-items-center',
      'd-flex',
      'mt-4',
      'mt-md-0',
      'mx-auto',
      'overflow-hidden',
      'rounded-circle',
    ].join(' ');
    return (
      <div className={className}>
        <img className="cover" src={tea} />
        <div className="hover text-center">
          <div className="follow mx-auto text-uppercase text-white">Follow Us</div>
          <div className="icons text-white">
            <hr className="bg-white mt-0 mb-3" />
            <ReactGA.OutboundLink
              eventLabel="Visit UV & Tea Facebook"
              to="https://www.facebook.com/UVNTea/"
              target="_blank"
              rel="nofollow">
              <FontAwesomeIcon className="mr-4" icon={faFacebookF} />
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="Visit UV & Tea Twitter"
              to="https://twitter.com/UVNTea/"
              target="_blank"
              rel="nofollow">
              <FontAwesomeIcon className="mr-4" icon={faTwitter} />
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="Visit UV & Tea Pinterest"
              to="https://www.pinterest.com/uvntea/"
              target="_blank"
              rel="nofollow">
              <FontAwesomeIcon className="mr-4" icon={faPinterestP} />
            </ReactGA.OutboundLink>
            <ReactGA.OutboundLink
              eventLabel="Visit UV & Tea Instagram"
              to="https://www.instagram.com/uv_n_tea/"
              target="_blank"
              rel="nofollow">
              <FontAwesomeIcon icon={faInstagram} />
            </ReactGA.OutboundLink>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialMedia;
