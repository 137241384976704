import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Button, Form, FormText, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import validator from 'validator';

interface State {
  email: string;
  error: string;
  submitted: boolean;
  success: string;
}

class Subscribe extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      error: '',
      submitted: false,
      success: '',
    };
  }

  public render() {
    const { email, error, submitted, success } = this.state;

    return (
      <Form onSubmit={this.unsubscribe}>
        <InputGroup>
          <Input
            id="unsubscribe"
            invalid={!!error}
            onChange={this.change}
            placeholder="Email Address to Unsubscribe"
            type="text"
            valid={submitted && !error}
            value={email}
          />
          <InputGroupAddon addonType="append">
            <Button color="info" disabled={submitted}>
              Unsubscribe
            </Button>
          </InputGroupAddon>
        </InputGroup>
        <FormText color="danger">{error}</FormText>
        <FormText color="success">{success}</FormText>
      </Form>
    );
  }

  private change = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  };

  private unsubscribe = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email } = this.state;

    if (!validator.isEmail(email)) {
      this.setState({ error: 'Please input an email address to unsubscribe' });
      return;
    }

    this.setState({ submitted: true });

    try {
      await firebase.firestore().collection('users').doc(email.toLowerCase()).set(
        {
          mailingListApproved: false,
        },
        {
          merge: true,
        }
      );

      ReactGA.event({
        action: 'Unsubscribed',
        category: 'User',
      });
    } finally {
      this.setState({
        email: '',
        error: '',
        submitted: false,
        success: `Successfully unsubscribed ${email} from mailing lists`,
      });
    }
  };
}

export default Subscribe;
