import React, { Component } from 'react';

import bath from '../../../images/face-in-bath.jpg';
import ImageSection from './ImageSection';
import MailingList from './MailingList';

import './About.scss';

class About extends Component {
  public render() {
    return (
      <ImageSection
        className="About p-4 p-md-5 text-center"
        color="green"
        image={bath}
        imageSide="left">
        <h1 className="font-weight-light mb-4">What&apos;s the Tea?</h1>
        <p>
          To share in the principles of preventative aging and skincare management through the
          diligent use of sunscreen, lip balm, and antioxidant-rich tea. To promote a lifestyle of
          skin protection and rejuvenation.
        </p>
        <hr />
        <MailingList />
      </ImageSection>
    );
  }
}

export default About;
