import * as firebase from 'firebase/app';
import 'firebase/firestore';
import Papa from 'papaparse';
import React, { Component } from 'react';
import { Button } from 'reactstrap';

interface Props {
  accounts: firebase.firestore.DocumentSnapshot[];
  className?: string;
}

interface State {
  csv: string;
}

interface Data {
  Address: string;
  'Address Line 2': string;
  City: string;
  'Email Address': string;
  Name: string;
  Phone: string;
  State: string;
  Zipcode: string;
}

class ExportShipping extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      csv: '',
    };
  }

  public componentDidMount() {
    this.computeCSV();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.accounts !== this.props.accounts) {
      this.computeCSV();
    }
  }

  public render() {
    const { accounts, className } = this.props;
    const { csv } = this.state;

    if (accounts.length === 0) {
      return null;
    }

    return (
      <a href={csv} download="shipping.csv">
        <Button className={className || ''} color="info">
          Download Shipping
        </Button>
      </a>
    );
  }

  private computeCSV = () => {
    const { accounts } = this.props;
    const data = accounts.map(this.getAccountData).filter((data) => !!data);
    const csv = encodeURI('data:text/csv;charset=utf-8,' + Papa.unparse(data));
    this.setState({ csv });
  };

  private getAccountData = (account: firebase.firestore.DocumentSnapshot): Data => {
    return {
      Address: (account.get('address') as string) || '',
      'Address Line 2': (account.get('address2') as string) || '',
      City: (account.get('city') as string) || '',
      'Email Address': account.id,
      Name: (account.get('name') as string) || '',
      Phone: (account.get('phone') as string) || '',
      State: (account.get('state') as string) || '',
      Zipcode: (account.get('zip') as string) || '',
    };
  };
}

export default ExportShipping;
