import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

interface Props {
  details?: firebase.firestore.DocumentSnapshot;
  email: string;
  hidden: boolean;
  loadData: () => Promise<void>;
}

interface State {
  submitted: boolean;
}

class MailingList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  public render() {
    const { hidden } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <div className="mb-5">
        <h5>Mailing List Status:</h5>
        {this.renderStatus()}
      </div>
    );
  }

  private renderStatus = () => {
    const { details } = this.props;
    const { submitted } = this.state;
    const mailingListApproved = !!details && !!details.get('mailingListApproved');

    let content;
    if (submitted) {
      content = '...';
    } else {
      content = (
        <React.Fragment>
          {mailingListApproved ? 'Subscribed' : 'Unsubscribed'} (
          <a href="#" onClick={this.changeSubscription(!mailingListApproved)}>
            {mailingListApproved ? 'unsubscribe' : 'subscribe'}
          </a>
          )
        </React.Fragment>
      );
    }
    return (
      <p>
        {content}
        <br />
        <small className="mb-4 mt-0 text-muted">
          Read our{' '}
          <Link className="text-muted" to="/privacy">
            Privacy Policy
          </Link>
        </small>
      </p>
    );
  };

  private changeSubscription = (mailingListApproved: boolean) => async (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();

    const { email, loadData } = this.props;

    this.setState({ submitted: true });

    try {
      await firebase.firestore().collection('users').doc(email.toLowerCase()).set(
        {
          mailingListApproved,
        },
        {
          merge: true,
        }
      );

      ReactGA.event({
        action: mailingListApproved ? 'Subscribed' : 'Unsubscribed',
        category: 'User',
      });

      await loadData();
    } finally {
      this.setState({ submitted: false });
    }
  };
}

export default MailingList;
