import React, { Component } from 'react';
import { Card, CardImg, CardImgOverlay, CardText, CardTitle } from 'reactstrap';

import { Color } from '../../../types';

import './PromoCard.scss';

interface Props {
  active: boolean;
  color: Color;
  image: string;
  title: string;
}

class PromoCard extends Component<Props> {
  public render() {
    const { active, children, color, image, title } = this.props;

    const classNames = ['PromoCard', 'border-0', 'd-md-block', 'mb-0', 'text-left', color];
    if (!active) {
      classNames.push('d-none');
    }

    return (
      <Card className={classNames.join(' ')}>
        <CardImg src={image} />
        <CardImgOverlay>
          <CardTitle className="text-uppercase">{title}</CardTitle>
          <CardText>{children}</CardText>
        </CardImgOverlay>
      </Card>
    );
  }
}

export default PromoCard;
