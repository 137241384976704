import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import Limit from './Limit';

import './Paginate.scss';

interface Props {
  changeLimit: (limit: number) => void;
  first: () => void;
  limit: number;
  next: () => void;
  numDocs: number;
  pageNum: number;
  selectedAccounts: firebase.firestore.DocumentSnapshot[];
  userCount: number;
}

class Paginate extends Component<Props> {
  public render() {
    const { changeLimit, limit, numDocs, pageNum, userCount } = this.props;
    const userStart = pageNum * limit;

    if (numDocs === 0) {
      return null;
    }

    return (
      <div className="Paginate bg-white clearfix d-flex mb-4 px-5 py-3">
        {this.renderPagination()}
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <strong>Users</strong>
          </div>
          <div className="mr-2">
            {userStart + 1} - {userStart + numDocs}
          </div>
          <div className="mr-2">|</div>
          <div className="mr-2">
            <strong>Total</strong>
          </div>
          <div className="mr-2">{userCount}</div>
          {this.renderSelectedAmount()}
        </div>
        <Limit
          className="d-flex flex-grow-1 flex-row-reverse"
          changeLimit={changeLimit}
          limit={limit}
        />
      </div>
    );
  }

  private renderPagination() {
    const { first, limit, next } = this.props;

    if (limit !== 0) {
      return (
        <Pagination className="mr-4">
          <PaginationItem>
            <PaginationLink href="#" onClick={first}>
              First
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" onClick={next}>
              Next
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      );
    }
    return null;
  }

  private renderSelectedAmount = () => {
    const { selectedAccounts } = this.props;

    if (!selectedAccounts || selectedAccounts.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="mr-2">|</div>
        <div className="mr-2">
          <strong>Selected</strong>
        </div>
        <div className="mr-2">{selectedAccounts.length}</div>
      </React.Fragment>
    );
  };
}

export default Paginate;
