import {
  Address,
  explodeAddress as explodeAddressSync,
  implodeAddress as implodeAddressSync,
} from 'parse-address-string';

const explodeAddress = (address: string): Promise<Address> => {
  return new Promise((res, rej) => {
    explodeAddressSync(address, (err, address) => {
      if (err) {
        rej('Error');
      }
      res(address);
    });
  });
};

const implodeAddress = (address: Address): Promise<string> => {
  return new Promise((res, rej) => {
    implodeAddressSync(address, (err, address) => {
      if (err) {
        rej('Error');
      }
      res(address);
    });
  });
};

export default explodeAddress;
export { explodeAddress, implodeAddress };
