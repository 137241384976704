import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import './Footer.scss';

class Footer extends Component {
  public render() {
    return (
      <Container className="Footer">
        <Row className="pb-3 pt-5 text-white">
          <Col xs={6}>©{new Date().getFullYear()} by UV & Tea.</Col>
          <Col className="text-right" xs={6}>
            <Link to="/privacy" className="text-white">
              Privacy Policy
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
