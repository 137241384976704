import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Section from './Section';

import './Faq.scss';

interface State {
  scrollTarget: HTMLDivElement | null;
  selectedCategory: Category | null;
  selectedQuestion: FAQ | null;
}

interface Category {
  name: string;
  questions: FAQ[];
}

interface FAQ {
  question: string;
  answer: React.ReactNode;
}

/* eslint-disable sort-keys */
class About extends Component<any, State> {
  private scrollbar: HTMLElement | null;

  constructor(props: any) {
    super(props);
    this.state = {
      scrollTarget: null,
      selectedCategory: null,
      selectedQuestion: null,
    };
    this.scrollbar = null;
  }

  public componentDidUpdate() {
    if (this.state.scrollTarget !== null) {
      if (this.scrollbar) {
        this.scrollbar.scrollTop = Math.max(this.state.scrollTarget.offsetTop - 24, 0);
      }
      this.setState({ scrollTarget: null });
    }
  }

  public render() {
    const { selectedQuestion } = this.state;

    return (
      <Section color="yellow" mobileTitle="FAQs" title="Frequently Asked Questions">
        {{
          left: {
            className: 'Questions py-3 py-md-4 pl-md-4 pr-md-2',
            element: (
              <PerfectScrollbar containerRef={(ref) => (this.scrollbar = ref)}>
                <div className="questions rounded p-3">
                  {CATEGORIES.map((category) => (
                    <div key={category.name} className={'category'}>
                      {this.renderCategory(category)}
                    </div>
                  ))}
                </div>
              </PerfectScrollbar>
            ),
          },
          right: {
            className: 'Answers d-none d-md-block py-4 pr-4 pl-2',
            element: (
              <PerfectScrollbar>
                <div className="answer rounded p-3">
                  {selectedQuestion ? selectedQuestion.answer : DEFAULT_ANSWER}
                </div>
              </PerfectScrollbar>
            ),
          },
        }}
      </Section>
    );
  }

  private renderCategory = (category: Category) => {
    const { selectedCategory } = this.state;

    return (
      <React.Fragment>
        <span onClick={this.selectCategory(category)}>
          <h5 className={category === selectedCategory ? 'selected' : ''}>{category.name}</h5>
        </span>
        {category === selectedCategory && category.questions.map(this.renderQuestion)}
      </React.Fragment>
    );
  };

  private renderQuestion = (faq: FAQ) => {
    const { selectedQuestion } = this.state;

    let className = 'question';
    let answer;
    if (faq === selectedQuestion) {
      className += ' selected';
      answer = <div className="answer border rounded d-block d-md-none p-2 ml-3">{faq.answer}</div>;
    }
    return (
      <React.Fragment key={faq.question}>
        <div className={className}>
          <span onClick={this.selectQuestion(faq)}>{faq.question}</span>
        </div>
        {answer}
      </React.Fragment>
    );
  };

  private selectCategory = (category: Category | null) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (this.state.selectedCategory === category) {
      category = null;
    }
    this.setState({
      scrollTarget: e.currentTarget,
      selectedCategory: category,
      selectedQuestion: null,
    });
  };

  private selectQuestion = (faq: FAQ) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.setState({ scrollTarget: e.currentTarget, selectedQuestion: faq });
  };
}
const DEFAULT_ANSWER = (
  <p>
    Can&apos;t find an answer to your question? Please contact{' '}
    <a href="mailto:support@uvtea.com">support@uvtea.com</a> and we will do our best to help you.
  </p>
);
const CATEGORIES: Category[] = [
  {
    name: 'SUBSCRIPTION BOX FEATURES',
    questions: [
      {
        question: 'What comes in the box?',
        answer: (
          <React.Fragment>
            <p>
              UV & Tea is not a sample subscription box - we are a health focused subscription
              service, equipping our subscribers with a full month supply of product. Here’s what we
              deliver:
            </p>
            <ul>
              <li>1 full size sunscreen</li>
              <li>1 full size lip treatment</li>
              <li>1 full size loose leaf tea (min 1 oz)</li>
              <li>Bonus themed items and samples</li>
            </ul>
            <p>These products are meant to last through the month in daily ritual application.</p>
          </React.Fragment>
        ),
      },
      {
        question: 'Why these items?',
        answer: (
          <p>
            We believe that skincare is a holistic process, where the sum is greater than its parts.
            Applying sunscreen to prevent sun damage, lip treatments to protect and prevent
            degradation to lips, and tea in supplying antioxidants and hydration to your skin, all
            work together as a formula for beautiful skin. When applied together daily, these
            products work as the basis for a powerful beauty routine.
          </p>
        ),
      },
      {
        question: 'What else is in the box?',
        answer: (
          <p>
            We have themed each month’s subscription to include fun bonus items and samples of
            products we recently discovered.
          </p>
        ),
      },
      {
        question: 'What about box sustainability?',
        answer: (
          <p>
            We attempt to source environmentally sustainable products for our packaging wherever
            possible. We use wooden boxes, which are fully biodegradable but can also be repurposed
            for maximum reusability. Our packaging materials are fully recyclable or compostable
            wherever possible.
          </p>
        ),
      },
      {
        question: 'Are the products full-size?',
        answer: (
          <p>
            Yes, the Products included in our monthly subscription box are the exact same that
            you&apos;d buy elsewhere. The brands we feature align with UV & Tea’s mission of
            delivering healthy, effective and cruelty-free beauty products.
          </p>
        ),
      },
      {
        question: 'What if I have allergies or sensitivities?',
        answer: (
          <p>
            Please contact <a href="mailto:support@uvtea.com">support@uvtea.com</a> so we can keep
            that information on file and assist you.
          </p>
        ),
      },
      {
        question: 'What if I can’t have caffeine?',
        answer: (
          <p>
            There are many antioxidant-rich teas that don&apos;t contain caffeine, so we will always
            provide a caffeine-free alternative when necessary. In order to be sure you receive only
            caffeine-free products, please pick the “Caffeine Free” option when selecting your
            subscription plan.
          </p>
        ),
      },
      {
        question: 'Do you include makeup in the box?',
        answer: (
          <p>
            The focus of our subscription box is sun protection and antioxidants. We do not plan to
            feature any makeup at this time.
          </p>
        ),
      },
    ],
  },
  {
    name: 'PRODUCT STANDARDS',
    questions: [
      {
        question: 'What standards do you have for your products?',
        answer: (
          <p>
            We feature eco-friendly sunscreen, vegan lip treatments and fair trade/organic tea in
            our box. Most of our products are gluten and GMO-free. All our brands verify that their
            products and raw ingredients have never been tested on animals. Beyond that, all of our
            sunscreens are FDA approved, meaning they meet the following{' '}
            {/* eslint-disable-next-line max-len */}
            <a href="https://www.fda.gov/drugs/resourcesforyou/consumers/buyingusingmedicinesafely/understandingover-the-countermedicines/ucm258468.htm">
              FDA standards
            </a>
            . If you have questions about any of the ingredients in our brands’ formulas, do not
            hesitate to email us at support@uvtea.com.
          </p>
        ),
      },
      {
        question: 'Is sunscreen toxic?',
        answer: (
          <p>
            Our product selections never contain any of the cosmetic ingredients banned for use in
            the United States, European Union, Canada, or Japan. Per the FDA’s{' '}
            <a
              href="https://www.fda.gov/NewsEvents/Newsroom/PressAnnouncements/ucm631736.htm"
              rel="nofollow noopener noreferrer"
              target="_blank">
              most recent outline for sunscreen
            </a>{' '}
            (as it is an over the counter drug that is monitored by the FDA) mineral sunscreens,
            such as zinc oxide and titanium dioxide, are the safest sunscreens available. The
            Environmental Working Group also agrees that mineral sunscreens are{' '}
            <a
              href="https://www.ewg.org/sunscreen/report/the-trouble-with-sunscreen-chemicals/"
              rel="nofollow noopener noreferrer"
              target="_blank">
              effective and safe.
            </a>
          </p>
        ),
      },
      {
        question: 'Are your products 100% vegan?',
        answer: (
          <p>
            While the majority of our products are vegan, you may occasionally find a product that
            contains honey or beeswax. Please send your ingredient concerns to us at{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a>.
          </p>
        ),
      },
    ],
  },
  {
    name: 'SUBSCRIPTION OPTIONS',
    questions: [
      {
        question: 'What subscription plans do you offer?',
        answer: (
          <p>
            UV & Tea offers a month-to-month subscription. Subscriptions automatically renew on the
            26th of the month, but may be canceled at any time before then to prevent them from
            renewing. We are considering longer term options in the future.
          </p>
        ),
      },
      {
        question: 'How much does a subscription cost?',
        answer: <p>A month to month subscription is $36.99/month.</p>,
      },
      {
        question: 'Can I skip a month while I’m on vacation?',
        answer: (
          <p>
            To forecast properly for our service, we do not offer the ability to skip a month of
            your subscription. However, we can hold your box and ship it later. Please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a> to request assistance with
            delaying your subscription delivery.
          </p>
        ),
      },
    ],
  },
  {
    name: 'SHIPPING AND DELIVERY',
    questions: [
      {
        question: 'How much does shipping cost?',
        answer: (
          <p>
            Shipping is free for all orders, however we do not ship outside of the United States.
          </p>
        ),
      },
      {
        question: 'When are the boxes shipped?',
        answer: <p>Our boxes are shipped on the 1st working day of each month.</p>,
      },
      {
        question: 'What if my box arrives damaged?',
        answer: (
          <p>
            Please contact <a href="mailto:support@uvtea.com">support@uvtea.com</a> and we will help
            resolve the situation.
          </p>
        ),
      },
      {
        question: 'When can I expect my first delivery to arrive?',
        answer: (
          <p>
            Your first box will arrive the month following your subscription, as long as you
            subscribe before the 20th of that month. Packages are shipped on the 1st working day of
            each month, and usually arrive during the first week of the month. You will receive an
            email shipping notification and tracking number so you can easily track your package.
          </p>
        ),
      },
      {
        question: 'What if I miss the cutoff for the current month’s box?',
        answer: (
          <p>
            If you miss the cutoff date for a month’s box, please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a> to see if we have any extra
            stock. If so we would be glad to send you a special shipment.
          </p>
        ),
      },
      {
        question: 'Where do you ship to? ',
        answer: (
          <p>
            We ship anywhere within the United States, including Alaska and Hawaii, via USPS
            priority or regular mail.
          </p>
        ),
      },
      {
        question: 'Where’s my package?',
        answer: (
          <p>
            While most of our shipments arrive without issue, there can always be extenuating
            circumstances that results in the loss of a package. If you believe your package has
            been lost, please contact <a href="mailto:support@uvtea.com">support@uvtea.com</a> for a
            resolution.
          </p>
        ),
      },
    ],
  },
  {
    name: 'ACCOUNT',
    questions: [
      {
        question: 'How do I create an account?',
        answer: (
          <p>
            Click the Sign In link at the top right of the page. You will have the option of
            creating an account, or signing in with your Facebook or Google account.
          </p>
        ),
      },
      {
        question: 'How do I update my shipping address?',
        answer: (
          <p>
            You must sign in to your account to update your shipping address. If you do not yet have
            an account, simply create an account with the email address you used to create your
            subscription. You may then update your shipping address using the “Edit Details” link in
            the top right corner. For help, please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a>.
          </p>
        ),
      },
    ],
  },
  {
    name: 'PAYMENT',
    questions: [
      {
        question: 'What method of payment do you accept?',
        answer: (
          <p>We accept credit card payment by Visa, Mastercard, Discover and American Express.</p>
        ),
      },
      {
        question: 'What day do you charge?',
        answer: (
          <p>
            We take payment on the 26th of the month and ship your box on the 1st working day of the
            next month. For example, if we ship your box on June 1st, payment will be taken on May
            26th.
          </p>
        ),
      },
      {
        question: 'How do I change my billing information?',
        answer: (
          <p>
            You must sign in to your account to update your billing information. If you do not yet
            have an account, simply create an account with the email address you used to create your
            subscription. You may then update your billing information using the “Edit Details” link
            in the top right corner. For help, please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a>.
          </p>
        ),
      },
    ],
  },
  {
    name: 'RETURN POLICY',
    questions: [
      {
        question: 'Can I return or get a refund for my subscription?',
        answer: (
          <p>
            While we do not accept returns on our monthly subscription, we want you to be able to
            use and enjoy each product we deliver to you. Please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a> as soon as you join to let us
            know if you have allergies or sensitivities to certain ingredients or have specific
            ingredient concerns.
          </p>
        ),
      },
      {
        question: 'What if my product arrives damaged?',
        answer: (
          <p>
            We will replace any product that arrives damaged or defective. Please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a> for a resolution.
          </p>
        ),
      },
    ],
  },
  {
    name: 'CANCELLATIONS',
    questions: [
      {
        question: 'How do I cancel my subscription?',
        answer: (
          <p>
            You must sign in to your account to cancel your subscription. If you do not yet have an
            account, simply create an account with the email address you used to create your
            subscription. You may then cancel your account using the “Edit Details” link in the top
            right corner. For help, please contact{' '}
            <a href="mailto:support@uvtea.com">support@uvtea.com</a>.
          </p>
        ),
      },
      {
        question: 'When can I cancel my subscription?',
        answer: (
          <p>
            You may cancel your subscription at any point before you are charged on the 26th of the
            month. If you miss this cutoff, due to forecasting reasons, we regretfully cannot refund
            your payment. Instead you will receive your next box as usual, and your subscription
            will be canceled moving forward.
          </p>
        ),
      },
    ],
  },
];

export default About;
