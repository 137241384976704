import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

import AboutMe from './AboutMe';
import SocialMedia from './SocialMedia';

class Social extends Component {
  public render() {
    return (
      <Container className="Social">
        <Row>
          <Col md={6}>
            <AboutMe />
          </Col>
          <Col md={6}>
            <SocialMedia />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Social;
