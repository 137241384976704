import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { formatIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { Address as GoogleAddress } from 'parse-address-string';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import stripe from 'stripe';
import validator from 'validator';

import AddressAutocomplete from '../utility/AddressAutocomplete';
import Avatar from '../utility/Avatar';
import USPS from '../../lib/async-usps-webtools';
import Delete from './Delete';
import MailingList from './MailingList';
import Subscription from './Subscription';

import './Details.scss';

interface State extends Fields<string> {
  customer?: stripe.Customer;
  details?: firebase.firestore.DocumentSnapshot;
  email: string;
  errors: Fields<string>;
  loading: boolean;
  submitted: boolean;
  success: string;
  touched: Fields<boolean>;
  user: firebase.User | null;
}

interface Fields<T> {
  address: T;
  address2: T;
  city: T;
  name: T;
  phone: T;
  state: T;
  zip: T;
}
type Field = keyof Fields<void>;

class Details extends Component<any, State> {
  /* eslint-disable */
  private sortOrder: Fields<number> = {
    name: 1,
    phone: 2,
    address: 3,
    address2: 4,
    city: 5,
    state: 6,
    zip: 7,
  };
  /* eslint-enable */

  constructor(props: any) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      details: undefined,
      email: '',
      errors: this.initializeFields(''),
      loading: true,
      submitted: false,
      success: '',
      touched: this.initializeFields(false),
      user: firebase.auth().currentUser,
    };
  }

  public componentDidMount() {
    ReactGA.pageview('/details');
    void this.loadData();
  }

  public render() {
    const {
      address,
      address2,
      city,
      customer,
      details,
      email,
      loading,
      name,
      phone,
      state,
      success,
      user,
      zip,
    } = this.state;

    if (!user || !user.email) {
      return <Redirect to="/" />;
    }

    let content;
    if (!user.emailVerified) {
      content = (
        <Container className="Details">
          <div className="details-info p-5 rounded text-center">
            <p>
              You must verify your email address before you can edit your details. Please check your
              email for a link to verify your email address with UV & Tea.
              <br />
              <a href="#" onClick={this.resendVerification}>
                Resend Verification Email
              </a>
            </p>
          </div>
        </Container>
      );
    } else {
      content = (
        <Row>
          <Col className="mb-4 mb-md-0" md={4}>
            <div className="details-info p-5 rounded text-left">
              <Avatar className="d-block mb-4 mx-auto" user={user} size={200} />
              <div className="mb-4">
                <h5>Email Address:</h5>
                <p>{email}</p>
              </div>
              <MailingList
                details={details}
                email={email}
                hidden={loading}
                loadData={this.loadData}
              />
              <Delete email={email} hidden={loading} />
            </div>
          </Col>
          <Col md={8}>
            <div className="subscription-form mb-4 p-5 p-md-5 rounded">
              <h2 className="mb-4">Subscription Details</h2>
              <Subscription customer={customer} hidden={loading} loadData={this.loadData} />
            </div>
            <div className="details-form p-4 p-md-5 rounded">
              <h2 className="mb-4">User Details</h2>
              <Form onSubmit={this.submit} hidden={loading}>
                <FormGroup row={true}>
                  <Col md={6}>
                    <Label for="name">Name</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="name"
                      invalid={this.isInvalid('name')}
                      onChange={this.change('name')}
                      type="text"
                      valid={this.isValid('name')}
                      value={name}
                    />
                  </Col>
                  <Col md={6}>
                    <Label for="phone">Phone Number</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="phone"
                      invalid={this.isInvalid('phone')}
                      onChange={this.change('phone')}
                      type="text"
                      valid={this.isValid('phone')}
                      value={phone}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Col md={6}>
                    <Label for="address1">Address</Label>
                    <AddressAutocomplete
                      address={address}
                      className="mb-2 rounded-0"
                      id="address1"
                      invalid={this.isInvalid('address')}
                      onChange={this.changeValue('address')}
                      onSelect={this.autocompleteAddress}
                      valid={this.isValid('address')}
                    />
                  </Col>
                  <Col md={6}>
                    <Label for="address1">Apt / Unit / Suite / etc. (optional)</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="address2"
                      invalid={this.isInvalid('address2')}
                      onChange={this.change('address2')}
                      type="text"
                      valid={this.isValid('address2')}
                      value={address2}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row={true}>
                  <Col md={5}>
                    <Label for="city">City</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="city"
                      invalid={this.isInvalid('city')}
                      onChange={this.change('city')}
                      type="text"
                      valid={this.isValid('city')}
                      value={city}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="state">State</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="state"
                      invalid={this.isInvalid('state')}
                      onChange={this.change('state')}
                      type="text"
                      valid={this.isValid('state')}
                      value={state}
                    />
                  </Col>
                  <Col md={3}>
                    <Label for="zip">Zip Code</Label>
                    <Input
                      className="mb-2 rounded-0"
                      id="zip"
                      invalid={this.isInvalid('zip')}
                      onChange={this.change('zip')}
                      type="text"
                      valid={this.isValid('zip')}
                      value={zip}
                    />
                  </Col>
                </FormGroup>
                <Button
                  className="mt-4 px-5"
                  color="info"
                  disabled={!this.hasChanges() || this.someInvalid()}
                  size="lg">
                  Save
                </Button>
                <FormText color="danger">{this.getCurrentError()}</FormText>
                <FormText color="success">{success}</FormText>
              </Form>
            </div>
          </Col>
        </Row>
      );
    }

    return (
      <Container className="Details">
        <Helmet>
          <title>UV & Tea - Edit Details</title>
        </Helmet>
        {content}
      </Container>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.changeValue(field)(event.target.value);
  };

  private changeValue = (field: Field) => (value: string) => {
    const { touched } = this.state;
    this.setState({
      ...this.state,
      [field]: this.format(field, value),
      success: '',
      touched: { ...touched, [field]: true },
    });
  };

  private format = (field: Field, value: string): string => {
    switch (field) {
      case 'phone':
        if (value.length === this.state[field].length - 1) {
          return value;
        }
        return formatIncompletePhoneNumber(value, 'US');
    }
    return value;
  };

  private autocompleteAddress = (address: GoogleAddress) => {
    this.setState({
      address: address.street_address1 || '',
      address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state || '',
      touched: {
        ...this.state.touched,
        address: true,
        address2: true,
        city: true,
        state: true,
        zip: true,
      },
      zip: address.postal_code || '',
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid || this.getChangedFields().length < 1) {
      return;
    }

    const changes: Partial<Fields<string>> = {};
    this.getChangedFields().forEach((field) => (changes[field] = this.state[field]));

    await firebase
      .firestore()
      .collection('users')
      .doc(this.state.email.toLowerCase())
      .set(
        {
          ...changes,
        },
        {
          merge: true,
        }
      );

    ReactGA.event({ action: 'Edit Details', category: 'User' });

    this.setState({
      errors: this.initializeFields(''),
      submitted: false,
      success: 'Your details have been updated!',
      touched: this.initializeFields(false),
    });

    await this.loadData();
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate and normalize address against USPS
    try {
      const address = await USPS.verify({
        city: fields.city,
        state: fields.state,
        street1: fields.address,
        street2: fields.address2,
        zip: fields.zip,
      });
      fields.address = address.street1;
      fields.address2 = address.street2;
      fields.city = address.city;
      fields.state = address.state;
      fields.zip = address.zip;
    } catch (e) {
      const errorField = USPS.getErrorField((e as Error).message);
      if (errorField === null || errorField === 'street1' || errorField === 'street2') {
        errors.address = this.getError('address');
      } else {
        errors[errorField] = this.getError(errorField);
      }
    }

    // Validate fields
    this.getChangedFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, success: '', touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'phone':
        const number = parsePhoneNumberFromString(value, 'US');
        return !!number?.isValid();
      case 'zip':
        return validator.isNumeric(value) && value.length === 5;
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'address':
        return 'Please provide a valid street address';
      case 'address2':
        return '';
      case 'city':
        return 'Please provide a valid city';
      case 'name':
        return 'Please provide your name';
      case 'phone':
        return 'Please provide a valid phone number';
      case 'state':
        return 'Please provide a valid state';
      case 'zip':
        return 'Please provide a valid zip code';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getChangedFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      address: val,
      address2: val,
      city: val,
      name: val,
      phone: val,
      state: val,
      zip: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && this.getChangedFields().includes(field) && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return (
      submitted && this.getChangedFields().includes(field) && !touched[field] && !!errors[field]
    );
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };

  private getChangedFields = (): Field[] => {
    return this.getFields().filter(
      (field) => this.getCurrentSavedValue(field) !== this.state[field]
    );
  };

  private hasChanges = (): boolean => {
    return this.getChangedFields().length > 0;
  };

  private getCurrentSavedValue(field: Field): string {
    return (this.state.details?.get(field) as string) || '';
  }

  private loadData = async (): Promise<any> => {
    const user = this.state.user;
    if (!user || !user.email) {
      return;
    }
    const email = user.email;

    const details = await firebase.firestore().collection('users').doc(email.toLowerCase()).get();
    const fetchCustomer = firebase.functions().httpsCallable('fetchCustomer');
    const result = await fetchCustomer({ customer: details.get('customer') as string });
    const customer: stripe.Customer = result.data as stripe.Customer;

    const fields = this.initializeFields('');
    this.getFields().forEach((field) => (fields[field] = (details.get(field) as string) || ''));

    return new Promise((res) => {
      this.setState({ ...fields, customer, details, email, loading: false }, () => {
        res();
      });
    });
  };

  private resendVerification = async () => {
    const user = this.state.user;
    if (user) {
      await user.sendEmailVerification({
        url: new URL('/verify', window.location.href).href,
      });
    }
  };
}

export default Details;
