import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';

import Tag from './Tag';

interface Props {
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  className?: string;
  filterTag: firebase.firestore.DocumentReference | null;
  onDelete: (tag: firebase.firestore.DocumentReference) => void;
  onFilter?: (tag: firebase.firestore.DocumentReference) => void;
  tags: firebase.firestore.DocumentReference[];
}

class Tags extends Component<Props> {
  public render() {
    const { allTags, className, filterTag, onDelete, onFilter, tags } = this.props;

    return (
      <div className={className || ''}>
        {tags.map((t) => (
          <Tag
            key={t.id}
            allTags={allTags}
            onDelete={onDelete}
            onFilter={onFilter}
            selected={!!filterTag && filterTag.id === t.id}
            tag={t}
          />
        ))}
      </div>
    );
  }
}

export default Tags;
