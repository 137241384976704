import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';
import { Badge } from 'reactstrap';

interface Props {
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  onDelete: (tag: firebase.firestore.DocumentReference) => void;
  onFilter?: (tag: firebase.firestore.DocumentReference) => void;
  selected: boolean;
  tag: firebase.firestore.DocumentReference;
}

interface State {
  deleteHover: boolean;
}

class Tag extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteHover: false,
    };
  }

  public render() {
    const { allTags, selected, tag } = this.props;
    const { deleteHover } = this.state;

    const currentTag = allTags.find((t) => t.id === tag.id);
    if (!currentTag) {
      return null;
    }

    let iconColor = selected ? 'text-light' : 'text-secondary';
    if (deleteHover) {
      iconColor = 'text-danger';
    }

    return (
      <Badge
        className="Tag border mr-2 p-2"
        color={selected ? 'primary' : 'light'}
        href="#"
        onClick={this.filter}>
        <div className="d-inline-block mr-2">{currentTag.get('name')}</div>
        <div
          className="d-inline-block"
          onClick={this.delete}
          onMouseEnter={this.deleteEnter}
          onMouseLeave={this.deleteLeave}>
          <FontAwesomeIcon className={'tag-delete ' + iconColor} icon={faTimesCircle} />
        </div>
      </Badge>
    );
  }

  private delete = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onDelete(this.props.tag);
  };

  private deleteEnter = () => this.setState({ deleteHover: true });
  private deleteLeave = () => this.setState({ deleteHover: false });

  private filter = (event: React.MouseEvent<HTMLLinkElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.onFilter) {
      this.props.onFilter(this.props.tag);
    }
  };
}

export default Tag;
