import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Address as GoogleAddress } from 'parse-address-string';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import validator from 'validator';

import AddressAutocomplete from '../utility/AddressAutocomplete';

import USPS from '../../lib/async-usps-webtools';

import './FreeGift.scss';

interface Props {
  close: () => void;
  isOpen: boolean;
}

interface State extends Fields<string> {
  errors: Fields<string>;
  submitted: boolean;
  success: string;
  touched: Fields<boolean>;
}

interface Fields<T> {
  address: T;
  address2: T;
  city: T;
  email: T;
  name: T;
  privacy: T;
  state: T;
  zip: T;
}
type Field = keyof Fields<void>;

class FreeGift extends Component<Props, State> {
  private input: React.RefObject<HTMLInputElement>;

  /* eslint-disable */
  private sortOrder: Fields<number> = {
    name: 1,
    email: 2,
    address: 3,
    address2: 4,
    city: 5,
    state: 6,
    zip: 7,
    privacy: 8,
  };
  /* eslint-enable */

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      submitted: false,
      success: '',
      touched: {
        ...this.initializeFields(false),
      },
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    if (this.props.isOpen) {
      ReactGA.modalview('/free-gift');
    }
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.props.isOpen && !prevProps.isOpen) {
      ReactGA.modalview('/free-gift');
    }
  }

  public render() {
    const { close, isOpen } = this.props;
    const { address, address2, city, email, name, privacy, state, success, zip } = this.state;

    return (
      <Modal className="FreeGift" centered={true} isOpen={isOpen} size="lg" toggle={close}>
        <ModalHeader className="border-0 text-center" toggle={close}>
          Sign up for a free gift!
        </ModalHeader>
        <ModalBody className="free-gift-body p-3">
          <div className="free-gift-content p-4 text-white">
            <p>
              Register for our mailing list and input your name and mailing address and you will
              receive free samples in the mail.
            </p>
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  className="mb-2 rounded-0"
                  id="name"
                  innerRef={this.input}
                  invalid={this.isInvalid('name')}
                  onChange={this.change('name')}
                  type="text"
                  valid={this.isValid('name')}
                  value={name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email Address</Label>
                <Input
                  className="mb-2 rounded-0"
                  id="email"
                  invalid={this.isInvalid('email')}
                  onChange={this.change('email')}
                  type="text"
                  valid={this.isValid('email')}
                  value={email}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address1">Address</Label>
                <AddressAutocomplete
                  address={address}
                  className="mb-2 rounded-0"
                  id="address1"
                  invalid={this.isInvalid('address')}
                  onChange={this.changeValue('address')}
                  onSelect={this.autocompleteAddress}
                  valid={this.isValid('address')}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address2">Apt / Unit / Suite / etc. (optional)</Label>
                <Input
                  className="mb-2 rounded-0"
                  id="address2"
                  invalid={this.isInvalid('address2')}
                  onChange={this.change('address2')}
                  type="text"
                  valid={this.isValid('address2')}
                  value={address2}
                />
              </FormGroup>
              <FormGroup row={true}>
                <Col md={5}>
                  <Label for="city">City</Label>
                  <Input
                    className="mb-2 rounded-0"
                    id="city"
                    invalid={this.isInvalid('city')}
                    onChange={this.change('city')}
                    type="text"
                    valid={this.isValid('city')}
                    value={city}
                  />
                </Col>
                <Col md={4}>
                  <Label for="state">State</Label>
                  <Input
                    className="mb-2 rounded-0"
                    id="state"
                    invalid={this.isInvalid('state')}
                    onChange={this.change('state')}
                    type="text"
                    valid={this.isValid('state')}
                    value={state}
                  />
                </Col>
                <Col md={3}>
                  <Label for="zip">Zip Code</Label>
                  <Input
                    className="mb-2 rounded-0"
                    id="zip"
                    invalid={this.isInvalid('zip')}
                    onChange={this.change('zip')}
                    type="text"
                    valid={this.isValid('zip')}
                    value={zip}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="align-items-center mb-0" row={true}>
                <Col>
                  <CustomInput
                    checked={privacy === 'true'}
                    id="privacy"
                    inline={true}
                    invalid={this.isInvalid('privacy')}
                    onChange={this.change('privacy')}
                    type="checkbox"
                    valid={this.isValid('privacy')}>
                    I agree to the
                    <Link className="ml-1" to="/privacy">
                      Privacy Policy
                    </Link>
                  </CustomInput>
                </Col>
                <Col md={'auto'}>
                  <Button className="px-5" color="info" disabled={this.someInvalid()} size="lg">
                    Get Free Gift!
                  </Button>
                </Col>
              </FormGroup>
              <FormText color="danger">{this.getCurrentError()}</FormText>
              <FormText color="success">{success}</FormText>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === 'checkbox' ? event.target.checked.toString() : event.target.value;
    this.changeValue(field)(value);
  };

  private changeValue = (field: Field) => (value: string) => {
    const { touched } = this.state;

    this.setState({
      ...this.state,
      [field]: value,
      success: '',
      touched: { ...touched, [field]: true },
    });
  };

  private autocompleteAddress = (address: GoogleAddress) => {
    const { touched } = this.state;
    this.setState({
      address: address.street_address1 || '',
      address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state || '',
      touched: {
        ...touched,
        address: true,
        address2: true,
        city: true,
        state: true,
        zip: true,
      },
      zip: address.postal_code || '',
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const { close } = this.props;
    const { address, address2, city, email, name, state, zip } = this.state;

    const auth = firebase.auth();
    const isVerified = !!(
      auth.currentUser &&
      auth.currentUser.email &&
      auth.currentUser.email.toLowerCase() === email.toLowerCase() &&
      auth.currentUser.emailVerified
    );

    try {
      await firebase.firestore().collection('users').doc(email.toLowerCase()).set(
        {
          address,
          address2,
          city,
          isVerified,
          mailingListApproved: true,
          name,
          state,
          zip,
        },
        {
          merge: true,
        }
      );

      ReactGA.event({ action: 'Applied for free gift on popup', category: 'User' });
    } finally {
      close();
      this.setState({
        ...this.initializeFields(''),
        errors: this.initializeFields(''),
        submitted: false,
        success: '',
        touched: this.initializeFields(false),
      });
    }
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate and normalize address against USPS
    try {
      const address = await USPS.verify({
        city: fields.city,
        state: fields.state,
        street1: fields.address,
        street2: fields.address2,
        zip: fields.zip,
      });
      fields.address = address.street1;
      fields.address2 = address.street2;
      fields.city = address.city;
      fields.state = address.state;
      fields.zip = address.zip;
    } catch (e) {
      const errorField = USPS.getErrorField((e as Error).message);
      if (errorField === null || errorField === 'street1' || errorField === 'street2') {
        errors.address = this.getError('address');
      } else {
        errors[errorField] = this.getError(errorField);
      }
    }

    // Validate fields
    this.getFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, success: '', touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      case 'privacy':
        return value === 'true';
      case 'zip':
        return validator.isNumeric(value) && value.length === 5;
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'address':
        return 'Please provide a valid street address';
      case 'city':
        return 'Please provide a valid city';
      case 'email':
        return 'Please provide a valid email address';
      case 'name':
        return 'Please provide your name';
      case 'privacy':
        return 'Please accept our privacy policy';
      case 'state':
        return 'Please provide a valid state';
      case 'zip':
        return 'Please provide a valid zip code';
      default:
        return '';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      address: val,
      address2: val,
      city: val,
      email: val,
      name: val,
      privacy: val,
      state: val,
      zip: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };
}

export default FreeGift;
