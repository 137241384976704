import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { CSSProperties, Component } from 'react';
import Autocomplete from 'react-autocomplete';

interface Props {
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  canCreate?: boolean;
  className?: string;
  onSelect: (tag: firebase.firestore.DocumentReference) => void;
  tags: firebase.firestore.DocumentReference[];
}

interface State {
  name: string;
}

class Account extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  public render() {
    const { allTags, className, tags } = this.props;
    const { name } = this.state;

    const dummyTag = allTags.find(
      (tag) => (tag.get('name') as string).toLowerCase() === name.toLowerCase()
    )
      ? null
      : this.dummyTag();
    const items = [
      ...allTags
        .filter(
          (tag) =>
            tag &&
            !tags.some((t) => tag.id === t.id) &&
            (tag.get('name') as string).toLowerCase().includes(name.toLowerCase())
        )
        .slice(0, 5),
      dummyTag,
    ].filter((t) => !!t);

    const menuStyle: CSSProperties = {
      background: 'rgba(255, 255, 255, 0.9)',
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      fontSize: '90%',
      maxHeight: '50%',
      overflow: 'auto',
      padding: '2px 0',
      position: 'fixed',
      zIndex: 10,
    };

    return (
      <div className={className || ''}>
        <Autocomplete
          getItemValue={this.getItemValue}
          inputProps={{ onBlur: this.clear, onKeyDown: this.keyDown }}
          items={items}
          menuStyle={menuStyle}
          onChange={this.onChange}
          onSelect={this.onSelect}
          open={name.length > 0}
          renderInput={this.renderInput}
          renderItem={this.renderItem}
          value={name}
        />
      </div>
    );
  }

  private getItemValue = (item: firebase.firestore.QueryDocumentSnapshot) =>
    item.get('name') as string;

  private dummyTag = () =>
    this.props.canCreate ? { get: () => `Add "${this.state.name}"`, id: '' } : null;

  private onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ name: event.target.value });

  private onSelect = async (_: string, item: firebase.firestore.QueryDocumentSnapshot) => {
    const { canCreate, onSelect } = this.props;
    const { name } = this.state;

    if (!item.id && !canCreate) {
      return;
    }

    this.clear();

    let tag: firebase.firestore.DocumentReference;
    if (!item.id) {
      tag = await firebase.firestore().collection('tags').add({ name });
    } else {
      tag = item.ref;
    }

    onSelect(tag);
  };

  private renderInput = (props: React.HTMLProps<HTMLInputElement>) => {
    return <input className="form-control" placeholder="Enter tag name..." {...props} />;
  };

  private keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 27) {
      // ESC
      this.clear();
    }
  };

  private clear = () => {
    this.setState({ name: '' });
  };

  private renderItem = (item: firebase.firestore.QueryDocumentSnapshot, isHighlighted: boolean) => {
    const classNames = ['tag-suggestion', 'p-2'];
    if (isHighlighted) {
      classNames.push('bg-light');
    } else {
      classNames.push('bg-white');
    }
    if (!item.id) {
      classNames.push('font-weight-bold');
    }
    return (
      <div className={classNames.join(' ')} key={item.id}>
        {item.get('name')}
      </div>
    );
  };
}

export default Account;
