import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';

import ExportShipping from './ExportShipping';
import Tag from './Tag';
import TagAutocomplete from './TagAutocomplete';

interface Props {
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  filterTag: firebase.firestore.DocumentReference | null;
  onFilterAdd: (tag: firebase.firestore.DocumentReference) => void;
  onFilterRemove: (tag: firebase.firestore.DocumentReference) => void;
  selectedAccounts: firebase.firestore.DocumentSnapshot[];
}

class Filters extends Component<Props> {
  public render() {
    const { allTags, filterTag, onFilterAdd, onFilterRemove, selectedAccounts } = this.props;

    return (
      <div className="Filters bg-white d-flex mb-4 px-5 py-3">
        <TagAutocomplete
          className="d-inline-block mr-3"
          allTags={allTags}
          onSelect={onFilterAdd}
          tags={filterTag ? [filterTag] : []}
        />
        <div className="my-1">
          {filterTag && (
            <Tag allTags={allTags} onDelete={onFilterRemove} selected={true} tag={filterTag} />
          )}
        </div>
        <div className="d-flex flex-grow-1 flex-row-reverse">
          <ExportShipping accounts={selectedAccounts} />
        </div>
      </div>
    );
  }
}

export default Filters;
