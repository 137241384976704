import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './AboutMe.scss';

class AboutMe extends Component {
  public render() {
    return (
      <div className="AboutMe d-none d-md-block mx-auto px-7 py-5 rounded-circle text-center">
        <hr className="bg-dark" />
        <h3 className="text-uppercase font-weight-light mb-2">About</h3>
        <h1 className="font-weight-bold mb-0 text-white">Me</h1>
        <hr className="bg-dark mt-1" />
        <ReactGA.OutboundLink
          eventLabel="Visit YMIR About"
          to="https://www.yourmomisright.com/about"
          target="_blank"
          rel="nofollow">
          <div className="cover rounded-circle" />
        </ReactGA.OutboundLink>
      </div>
    );
  }
}

export default AboutMe;
