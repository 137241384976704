import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { Address as GoogleAddress } from 'parse-address-string';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { CardElement, ReactStripeElements, injectStripe } from 'react-stripe-elements';
import { Button, Col, CustomInput, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import stripe from 'stripe';
import validator from 'validator';

import AddressAutocomplete from '../utility/AddressAutocomplete';

import USPS from '../../lib/async-usps-webtools';

import poweredByStripe from '../../images/stripe.svg';
import Confirm from './Confirm';

import './Subscribe.scss';

interface Props {
  details?: firebase.firestore.DocumentSnapshot;
  plan: stripe.Plan;
  user: firebase.User | null;
}

interface State extends Fields<string> {
  errors: Fields<string>;
  loading: boolean;
  submitted: boolean;
  success: boolean;
  touched: Fields<boolean>;
}

interface Fields<T> {
  address: T;
  address2: T;
  card: T;
  city: T;
  email: T;
  mailingListApproved: T;
  name: T;
  privacy: T;
  state: T;
  zip: T;
}
type Field = keyof Fields<void>;

class SubscriptionForm extends Component<Props & ReactStripeElements.InjectedStripeProps, State> {
  private input: React.RefObject<HTMLInputElement>;

  /* eslint-disable */
  private sortOrder: Fields<number> = {
    name: 1,
    email: 2,
    address: 3,
    address2: 4,
    city: 5,
    state: 6,
    zip: 7,
    card: 8,
    privacy: 9,
    mailingListApproved: 10,
  };
  /* eslint-enable */

  constructor(props: Props & ReactStripeElements.InjectedStripeProps) {
    super(props);

    const initialFields = this.initializeFields('');
    if (props.details) {
      const details = props.details;
      this.getFields().forEach((field) => {
        initialFields[field] = (details.get(field) as string) || '';
      });
    }
    if (props.user && props.user.email) {
      initialFields.email = props.user.email;
    }

    this.state = {
      ...initialFields,
      errors: {
        ...this.initializeFields(''),
        card: '',
      },
      loading: true,
      submitted: false,
      success: false,
      touched: this.initializeFields(false),
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    ReactGA.pageview('/subscribe/modal');
  }

  public render() {
    const {
      address,
      address2,
      city,
      email,
      errors,
      mailingListApproved,
      name,
      privacy,
      state,
      success,
      zip,
    } = this.state;
    if (success) {
      return <Confirm details={this.props.details} email={email} user={this.props.user} />;
    }

    return (
      <div className="SubscriptionForm">
        <Form onSubmit={this.submit}>
          <FormGroup row={true}>
            <Col md={6}>
              <Label for="name">Name</Label>
              <Input
                className="mb-2 rounded-0"
                id="name"
                innerRef={this.input}
                invalid={this.isInvalid('name')}
                onChange={this.change('name')}
                type="text"
                valid={this.isValid('name')}
                value={name}
              />
              {this.isInvalid('name') && <FormText color="danger">{errors.name}</FormText>}
            </Col>
            <Col md={6}>
              <Label for="email">Email Address</Label>
              <Input
                className="mb-2 rounded-0"
                id="email"
                invalid={this.isInvalid('email')}
                onChange={this.change('email')}
                type="text"
                valid={this.isValid('email')}
                value={email}
              />
              {this.isInvalid('email') && <FormText color="danger">{errors.email}</FormText>}
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Col md={6}>
              <Label for="address1">Address</Label>
              <AddressAutocomplete
                address={address}
                className="mb-2 rounded-0"
                id="address1"
                invalid={this.isInvalid('address')}
                onChange={this.changeValue('address')}
                onSelect={this.autocompleteAddress}
                valid={this.isValid('address')}
              />
              {this.isInvalid('address') && <FormText color="danger">{errors.address}</FormText>}
            </Col>
            <Col md={6}>
              <Label for="address2">Apt / Unit / Suite / etc. (optional)</Label>
              <Input
                className="mb-2 rounded-0"
                id="address2"
                onChange={this.change('address2')}
                type="text"
                value={address2}
              />
            </Col>
          </FormGroup>
          <FormGroup row={true}>
            <Col md={6}>
              <Label for="city">City</Label>
              <Input
                className="mb-2 rounded-0"
                id="city"
                invalid={this.isInvalid('city')}
                onChange={this.change('city')}
                type="text"
                valid={this.isValid('city')}
                value={city}
              />
              {this.isInvalid('city') && <FormText color="danger">{errors.city}</FormText>}
            </Col>
            <Col md={4}>
              <Label for="state">State</Label>
              <Input
                className="mb-2 rounded-0"
                id="state"
                invalid={this.isInvalid('state')}
                onChange={this.change('state')}
                type="text"
                valid={this.isValid('state')}
                value={state}
              />
              {this.isInvalid('state') && <FormText color="danger">{errors.state}</FormText>}
            </Col>
            <Col md={2}>
              <Label for="zip">Zip Code</Label>
              <Input
                className="mb-2 rounded-0"
                id="zip"
                invalid={this.isInvalid('zip')}
                onChange={this.change('zip')}
                type="text"
                valid={this.isValid('zip')}
                value={zip}
              />
              {this.isInvalid('zip') && <FormText color="danger">{errors.zip}</FormText>}
            </Col>
          </FormGroup>
          <FormGroup>
            <Label for="zip">Card Details</Label>
            <img className="float-right" src={poweredByStripe} alt="Powered by Stripe" />
            <CardElement
              hidePostalCode={true}
              onChange={this.changeCard}
              className="form-control"
              style={{ base: { lineHeight: '24px' } }}
            />
            {errors.card && <FormText color="danger">{errors.card}</FormText>}
          </FormGroup>
          <FormGroup row={true}>
            <Col md={6}>
              <CustomInput
                checked={privacy === 'true'}
                id="privacy"
                inline={true}
                invalid={this.isInvalid('privacy')}
                onChange={this.change('privacy')}
                type="checkbox"
                valid={this.isValid('privacy')}>
                I agree to the
                <Link className="ml-1" to="/privacy">
                  Privacy Policy
                </Link>
              </CustomInput>
              {this.isInvalid('privacy') && <FormText color="danger">{errors.privacy}</FormText>}
              <CustomInput
                checked={mailingListApproved === 'true'}
                id="mailingListApproved"
                inline={true}
                onChange={this.change('mailingListApproved')}
                type="checkbox">
                Subscribe to our mailing list
              </CustomInput>
            </Col>
            <Col md={6} className="text-right">
              <Button className="px-5" color="info" disabled={this.someInvalid()} size="lg">
                Subscribe
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      event.target.type === 'checkbox' ? event.target.checked.toString() : event.target.value;
    this.changeValue(field)(value);
  };

  private changeCard = () => {
    this.changeValue('card')('');
  };

  private changeValue = (field: Field) => (value: string) => {
    const { touched } = this.state;
    this.setState({
      ...this.state,
      [field]: value,
      touched: { ...touched, [field]: true },
    });
  };

  private autocompleteAddress = (address: GoogleAddress) => {
    this.setState({
      address: address.street_address1 || '',
      address2: address.street_address2 || '',
      city: address.city || '',
      state: address.state || '',
      touched: {
        ...this.state.touched,
        address: true,
        address2: true,
        city: true,
        state: true,
        zip: true,
      },
      zip: address.postal_code || '',
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const res = await this.getStripe().createToken({
      address_city: this.state.city,
      address_country: 'US',
      address_line1: this.state.address,
      address_line2: this.state.address2,
      address_state: this.state.state,
      address_zip: this.state.zip,
      name: this.state.name,
    });
    if (res.error && res.error.message) {
      this.setState({
        errors: {
          ...this.state.errors,
          card: res.error.message,
        },
      });
      return;
    }
    if (!res.token) {
      return;
    }

    let subscription: stripe.Subscription;
    try {
      const createSubscription = firebase.functions().httpsCallable('createSubscription');
      const result = await createSubscription({
        ...this.state,
        country: 'US',
        plan: this.props.plan.id,
        token: res.token.id,
      });
      subscription = result.data as stripe.Subscription;
    } catch (e) {
      return;
    }

    const changes: Partial<Fields<string>> = {};
    this.getChangedFields().forEach((field) => {
      if (field === 'card' || field === 'privacy') {
        return;
      }
      changes[field] = this.state[field];
    });

    await firebase
      .firestore()
      .collection('users')
      .doc(this.state.email.toLowerCase())
      .set(
        {
          ...changes,
          customer: subscription.customer,
          plan: this.props.plan.id,
        },
        {
          merge: true,
        }
      );

    ReactGA.event({
      action: 'Subscribed',
      category: 'Subscription',
    });

    this.setState({
      success: true,
    });
  };

  private validate = async (): Promise<boolean> => {
    const errors = {
      ...this.initializeFields(''),
      card: '',
    };
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate and normalize address against USPS
    try {
      const address = await USPS.verify({
        city: fields.city,
        state: fields.state,
        street1: fields.address,
        street2: fields.address2,
        zip: fields.zip,
      });
      fields.address = address.street1;
      fields.address2 = address.street2;
      fields.city = address.city;
      fields.state = address.state;
      fields.zip = address.zip;
    } catch (e) {
      const errorField = USPS.getErrorField((e as Error).message);
      if (errorField === null || errorField === 'street1' || errorField === 'street2') {
        errors.address = this.getError('address');
      } else {
        errors[errorField] = this.getError(errorField);
      }
    }

    // Validate fields
    this.getFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      case 'mailingListApproved':
        return true;
      case 'zip':
        return validator.isNumeric(value) && value.length === 5;
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'address':
        return 'Please provide a valid street address';
      case 'address2':
        return '';
      case 'card':
        return '';
      case 'city':
        return 'Please provide a valid city';
      case 'email':
        return 'Please provide a valid email address';
      case 'mailingListApproved':
        return '';
      case 'name':
        return 'Please provide your name';
      case 'privacy':
        return 'Please accept our Privacy Policy';
      case 'state':
        return 'Please provide a valid state';
      case 'zip':
        return 'Please provide a valid zip code';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      address: val,
      address2: val,
      card: val,
      city: val,
      email: val,
      mailingListApproved: val,
      name: val,
      privacy: val,
      state: val,
      zip: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };

  private getChangedFields = (): Field[] => {
    return this.getFields().filter(
      (field) => this.getCurrentSavedValue(field) !== this.state[field]
    );
  };

  private getCurrentSavedValue(field: Field): string {
    return (this.props.details?.get(field) as string) || '';
  }

  private getStripe(): ReactStripeElements.StripeProps {
    if (this.props.stripe) {
      return this.props.stripe;
    }
    throw new Error('Stripe not injected properly');
  }
}

export default injectStripe(SubscriptionForm);
