import * as firebase from 'firebase/app';
import 'firebase/functions';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Button, Form, FormText, Input } from 'reactstrap';
import validator from 'validator';

import drink from '../../../images/holding-drink.jpg';
import ImageSection from './ImageSection';

import './Contact.scss';

interface State extends Fields<string> {
  errors: Fields<string>;
  submitted: boolean;
  success: string;
  touched: Fields<boolean>;
}

interface Fields<T> {
  email: T;
  message: T;
  name: T;
  subject: T;
}
type Field = keyof Fields<void>;

class Contact extends Component<any, State> {
  /* eslint-disable */
  private sortOrder: Fields<number> = {
    name: 1,
    email: 2,
    subject: 3,
    message: 4,
  };
  /* eslint-enable */

  constructor(props: any) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      submitted: false,
      success: '',
      touched: {
        ...this.initializeFields(false),
      },
    };
  }

  public render() {
    const { email, message, name, subject, success } = this.state;

    return (
      <ImageSection className="Contact p-4 p-md-5" color="blue" image={drink} imageSide="left">
        <h2 className="font-weight-light mb-4 text-center">
          We would love to hear your suggestions or ideas
        </h2>
        <Form className="text-center" onSubmit={this.submit}>
          <Input
            className="mb-2 rounded-0"
            id="name"
            invalid={this.isInvalid('name')}
            onChange={this.change('name')}
            placeholder="Name"
            type="text"
            valid={this.isValid('name')}
            value={name}
          />
          <Input
            className="mb-2 rounded-0"
            id="email"
            invalid={this.isInvalid('email')}
            onChange={this.change('email')}
            placeholder="Email"
            type="text"
            valid={this.isValid('email')}
            value={email}
          />
          <Input
            className="mb-2 rounded-0"
            id="subject"
            invalid={this.isInvalid('subject')}
            onChange={this.change('subject')}
            placeholder="Subject"
            type="text"
            valid={this.isValid('subject')}
            value={subject}
          />
          <Input
            className="mb--1 rounded-0"
            id="message"
            invalid={this.isInvalid('message')}
            onChange={this.change('message')}
            placeholder="Message"
            type="textarea"
            rows={4}
            valid={this.isValid('message')}
            value={message}
          />
          <Button
            className="position-relative px-5"
            color="info"
            disabled={this.someInvalid()}
            size="lg">
            Send
          </Button>
          <FormText color="danger">{this.getCurrentError()}</FormText>
          <FormText color="success">{success}</FormText>
        </Form>
      </ImageSection>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { touched } = this.state;

    this.setState({
      ...this.state,
      [field]: event.target.value,
      success: '',
      touched: { ...touched, [field]: true },
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const supportEmail = firebase.functions().httpsCallable('supportEmail');
    const result = await supportEmail(this.state);

    ReactGA.event({ action: 'Sent suggestion', category: 'Contact' });

    this.setState({
      ...this.initializeFields(''),
      errors: this.initializeFields(''),
      submitted: false,
      success: result.data as string,
      touched: this.initializeFields(false),
    });
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });
    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, success: '', touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'email':
        return 'Please provide a valid email address';
      case 'message':
        return 'Please provide a message for your suggestion';
      case 'name':
        return 'Please provide your name';
      case 'subject':
        return 'Please provide a subject for your suggestion';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      email: val,
      message: val,
      name: val,
      subject: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };
}

export default Contact;
