import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { Color } from '../../../types';

import './Section.scss';

export type Side = 'left' | 'right';

type Children = { [key in Side]: Element };

interface Element {
  element: React.ReactNode;
  className?: string;
}

interface Props {
  breakout?: boolean;
  children: Children;
  color: Color;
  mobileTitle?: string;
  title?: string;
}

class Section extends Component<Props> {
  public render() {
    const { breakout, color, mobileTitle, title } = this.props;

    const classes = ['Section', 'mb-3', 'mb-md-6', 'overflow-hidden', color];
    if (breakout) {
      classes.push('rounded-0');
      classes.push('p-0');
    } else {
      classes.push('rounded');
    }

    let titleContent;
    if (mobileTitle || title) {
      classes.push('with-title');
      const baseTitleClass = 'title font-weight-light text-center';
      let titleClass = baseTitleClass;
      let mobileTitleClass = baseTitleClass;
      if (mobileTitle && title) {
        titleClass += ' d-none d-md-block';
        mobileTitleClass += ' d-block d-md-none';
      }
      titleContent = (
        <React.Fragment>
          {title && <h1 className={titleClass}>{title}</h1>}
          {mobileTitle && <h1 className={mobileTitleClass}>{mobileTitle}</h1>}
        </React.Fragment>
      );
    }

    return (
      <Container className={classes.filter((c) => !!c).join(' ')} fluid={breakout}>
        <Container>
          {titleContent}
          <Row className="mx-0">
            {this.renderColumn('left')}
            {this.renderColumn('right')}
          </Row>
        </Container>
      </Container>
    );
  }

  private renderColumn = (side: Side) => {
    const child = this.props.children[side];
    const className = child.className || 'overflow-hidden px-0';
    return (
      <Col className={className} md={6}>
        {child.element}
      </Col>
    );
  };
}

export default Section;
