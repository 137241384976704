import React, { Component } from 'react';
import { Container, Jumbotron } from 'reactstrap';

import logo from '../../../images/logo.png';
import Subscribe from './Subscribe';

import './Logo.scss';

class Logo extends Component {
  public render() {
    return (
      <Container className="Logo">
        <Jumbotron className="clearfix d-flex flex-row-reverse mb-3 mb-md-6 p-0 p-md-5">
          <div className="content d-flex flex-column">
            <img className="logo mb-md-4" src={logo} />
            <Subscribe />
          </div>
        </Jumbotron>
      </Container>
    );
  }
}

export default Logo;
