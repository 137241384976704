import { Address } from 'parse-address-string';
import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { Input } from 'reactstrap';

import poweredByGoogle from '../../images/powered_by_google.png';
import parseAddress from '../../lib/async-parse-address-string';

import './AddressAutocomplete.scss';

interface Props {
  address: string;
  className?: string;
  id?: string;
  invalid?: boolean;
  onChange: (value: string) => void;
  onSelect: (address: Address) => void;
  placeholder?: string;
  valid?: boolean;
}

interface State {
  address: string;
}

class AddressAutocomplete extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      address: props.address,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.address !== this.props.address) {
      this.setState({ address: this.props.address });
    }
  }

  public render() {
    const { className, id, invalid, placeholder, valid } = this.props;
    const { address } = this.state;
    return (
      <PlacesAutocomplete
        onChange={this.change}
        onSelect={this.onSelect}
        searchOptions={{ componentRestrictions: { country: 'US' } }}
        value={address}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="AddressAutocomplete position-relative">
              <Input
                {...getInputProps({
                  'aria-activedescendant': '',
                  className,
                  id,
                  invalid,
                  placeholder,
                  valid,
                })}
              />
              <div className="autocomplete-dropdown position-absolute text-muted">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const classNames = ['suggestion', 'p-2'];
                  if (suggestion.active) {
                    classNames.push('bg-light');
                  } else {
                    classNames.push('bg-white');
                  }
                  const props = getSuggestionItemProps(suggestion);
                  const { key, ...otherProps } = props;
                  return (
                    <div key={key} {...otherProps} className={classNames.join(' ')}>
                      {suggestion.description}
                    </div>
                  );
                })}
                {suggestions.length > 0 && (
                  <img
                    className="powered-by position-absolute"
                    src={poweredByGoogle}
                    alt="Powered by Google"
                  />
                )}
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }

  private change = (address: string) => {
    this.setState({ address });
    this.props.onChange(address);
  };

  private onSelect = async (_: string, placeId: string) => {
    const geoAddress = await geocodeByPlaceId(placeId);
    const parsed = await parseAddress(geoAddress[0].formatted_address);
    this.props.onSelect(parsed);
  };
}

export default AddressAutocomplete;
