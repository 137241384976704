import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import store from 'store';

import FreeGift from '../modal/FreeGift';
import Logo from './logo/Logo';
import Promo from './promo/Promo';
import About from './section/About';
import Blog from './section/Blog';
import Contact from './section/Contact';
import Faq from './section/Faq';
import Social from './social/Social';

interface State {
  freeGiftOpen: boolean;
}

class Home extends Component<any, State> {
  private FREE_GIFT_KEY = 'FREE_GIFT_FIRST_TIME';

  constructor(props: any) {
    super(props);
    this.state = {
      freeGiftOpen: false,
    };
  }

  public componentDidMount() {
    ReactGA.pageview('/');
  }

  public render() {
    const { freeGiftOpen } = this.state;

    return (
      <div className="Home">
        <Helmet>
          <title>UV & Tea - Home</title>
        </Helmet>
        <Logo />
        <Promo />
        <About />
        <Faq />
        <Waypoint onEnter={this.open}>
          <div>
            <Contact />
          </div>
        </Waypoint>
        <Blog />
        <Social />
        <FreeGift close={this.close} isOpen={freeGiftOpen} />
      </div>
    );
  }

  private open = () => {
    if (!store.get(this.FREE_GIFT_KEY)) {
      this.setState({ freeGiftOpen: true });
      store.set(this.FREE_GIFT_KEY, true);
    }
  };

  private close = () => {
    this.setState({ freeGiftOpen: false });
  };
}

export default Home;
