import 'firebase/auth';
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import ForgotPassword from './ForgotPassword';
import Signin from './Signin';
import Signup from './Signup';

import './AccountModal.scss';

interface Props {
  close: () => void;
  isOpen: boolean;
}

interface State {
  mode: Mode;
}

type Mode = 'signin' | 'signup' | 'forgotPassword';

class AccountModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mode: 'signin',
    };
  }

  public render() {
    const { close, isOpen } = this.props;
    const { mode } = this.state;

    let content;
    switch (mode) {
      case 'signin':
        content = (
          <Signin
            signup={this.setMode('signup')}
            forgotPassword={this.setMode('forgotPassword')}
            close={close}
          />
        );
        break;
      case 'signup':
        content = <Signup signin={this.setMode('signin')} close={close} />;
        break;
      case 'forgotPassword':
        content = <ForgotPassword signin={this.setMode('signin')} />;
        break;
    }

    return (
      <Modal className="AccountModal" centered={true} isOpen={isOpen} toggle={close}>
        <ModalBody className="account-modal-body p-3">{content}</ModalBody>
      </Modal>
    );
  }

  private setMode = (mode: Mode) => () => {
    this.setState({ mode });
  };
}

export default AccountModal;
