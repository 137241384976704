import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import Account from './Account';
import Filters from './Filters';
import Paginate from './Paginate';

interface State {
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  filterTag: firebase.firestore.DocumentReference | null;
  limit: number;
  pageNum: number;
  selectedAccounts: firebase.firestore.DocumentSnapshot[];
  snapshot: firebase.firestore.QuerySnapshot | null;
  userCount: number;
}
type LoadType = 'next';

class Admin extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      allTags: [],
      filterTag: null,
      limit: 10,
      pageNum: 0,
      selectedAccounts: [],
      snapshot: null,
      userCount: 0,
    };
  }

  public componentDidMount() {
    ReactGA.pageview('/admin');
    void this.loadData();

    firebase
      .firestore()
      .collection('tags')
      .onSnapshot((snapshot) => {
        this.setState({
          allTags: snapshot.docs.sort((d1, d2) =>
            (d1.get('name') as string).localeCompare(d2.get('name') as string)
          ),
        });
      });
  }

  public componentDidUpdate(_: any, prevState: State) {
    if (prevState.filterTag !== this.state.filterTag || prevState.limit !== this.state.limit) {
      void this.loadData();
    }
  }

  public render() {
    const {
      allTags,
      filterTag,
      limit,
      pageNum,
      selectedAccounts,
      snapshot,
      userCount,
    } = this.state;

    let docs: firebase.firestore.QueryDocumentSnapshot[] = [];
    if (snapshot) {
      docs = snapshot.docs;
    }

    return (
      <Container className="Admin">
        <Helmet>
          <title>UV & Tea - Admin</title>
        </Helmet>
        <Filters
          allTags={allTags}
          filterTag={filterTag}
          onFilterAdd={this.addFilterTag}
          onFilterRemove={this.removeFilterTag}
          selectedAccounts={selectedAccounts}
        />
        <Paginate
          changeLimit={this.changeLimit}
          first={this.first}
          limit={filterTag ? 0 : limit}
          next={this.next}
          numDocs={docs.length}
          pageNum={pageNum}
          selectedAccounts={selectedAccounts}
          userCount={userCount}
        />
        {docs.map((doc) => (
          <Account
            key={doc.id}
            account={doc}
            allTags={allTags}
            filterTag={filterTag}
            onFilter={this.addFilterTag}
            selected={!!selectedAccounts.find((account) => account.id === doc.id)}
            toggleSelected={this.toggleAccountSelected}
          />
        ))}
        <Paginate
          changeLimit={this.changeLimit}
          first={this.first}
          limit={filterTag ? 0 : limit}
          next={this.next}
          numDocs={docs.length}
          pageNum={pageNum}
          selectedAccounts={selectedAccounts}
          userCount={userCount}
        />
      </Container>
    );
  }

  private addFilterTag = (tag: firebase.firestore.DocumentReference) => {
    this.setState({ filterTag: tag });
  };

  private removeFilterTag = () => {
    this.setState({ filterTag: null });
  };

  private changeLimit = (limit: number) => {
    this.setState({ limit });
  };

  private toggleAccountSelected = (account: firebase.firestore.DocumentSnapshot) => {
    const { selectedAccounts } = this.state;
    if (selectedAccounts.includes(account)) {
      this.setState({ selectedAccounts: selectedAccounts.filter((acc) => acc !== account) });
    } else {
      this.setState({ selectedAccounts: [...selectedAccounts, account] });
    }
  };

  private first = () => {
    void this.loadData();
  };

  private next = () => {
    void this.loadData('next');
  };

  private loadData = async (loadType?: LoadType) => {
    const { filterTag, limit, pageNum } = this.state;
    const docs = (this.state.snapshot && this.state.snapshot.docs) || [];
    const db = firebase.firestore();

    let newPageNum = 0;
    let query = db.collection('admin').orderBy('created', 'desc');
    if (filterTag) {
      query = query.where('tags', 'array-contains', filterTag);
    } else {
      query = query.limit(limit);
    }
    if (loadType === 'next' && docs.length > 0) {
      newPageNum = pageNum + 1;
      query = query.startAfter(docs[docs.length - 1]);
    }
    const [snapshot, counter] = await Promise.all([
      query.get(),
      db.collection('counters').doc('users').get(),
    ]);
    this.setState({
      pageNum: newPageNum,
      selectedAccounts: [],
      snapshot,
      userCount: filterTag ? snapshot.size : (counter.get('count') as number),
    });
  };
}

export default Admin;
