import * as firebase from 'firebase/app';
import 'firebase/auth';
import md5 from 'md5';
import React, { Component } from 'react';

interface Props {
  className?: string;
  email?: string;
  size: number;
  user?: firebase.User;
}

class Avatar extends Component<Props> {
  public render() {
    const { className, size } = this.props;

    const src = this.getSrc();
    if (src) {
      return (
        <img
          className={`rounded-circle ${className || ''}`}
          style={{ height: size, width: size }}
          src={src}
          alt="Avatar"
        />
      );
    }
    return null;
  }

  private getSrc = () => {
    const { email, size, user } = this.props;

    // Special case facebook photos to grab a big one since photourl is tiny
    if (user && user.providerData.length > 0) {
      const data = user.providerData[0];
      if (data && data.providerId === 'facebook.com') {
        return `https://graph.facebook.com/${data.uid}/picture?height=${size}`;
      }
    }
    if (user && user.photoURL) {
      return user.photoURL;
    }
    const userEmail = email || (user && user.email);
    if (userEmail) {
      return `https://www.gravatar.com/avatar/${md5(userEmail)}?s=${size}`;
    }
    return null;
  };
}

export default Avatar;
