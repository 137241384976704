import React, { Component } from 'react';
import ReactGA from 'react-ga';

import { Color } from '../../../types';
import Section, { Side } from './Section';

import './Section.scss';

interface Props {
  breakout?: boolean;
  children: React.ReactNode;
  className?: string;
  color: Color;
  image: string;
  imageClickEvent?: string;
  imageLink?: string;
  imagePadding?: number;
  imageSide: Side;
}

class ImageSection extends Component<Props> {
  public render() {
    const { breakout, children, className, color, imageSide } = this.props;

    let left, right;
    const image = {
      className: this.imageClassName(),
      element: this.renderImage(),
    };
    const content = {
      className,
      element: children,
    };
    if (imageSide === 'left') {
      left = image;
      right = content;
    } else {
      left = content;
      right = image;
    }

    return (
      <Section breakout={breakout} color={color}>
        {{
          left,
          right,
        }}
      </Section>
    );
  }

  private renderImage = () => {
    const { image, imageClickEvent, imageLink } = this.props;
    let content = <img src={image} alt="image" />;
    if (imageLink) {
      if (!imageClickEvent) {
        throw new Error('Section with image link must have image click event set');
      }
      content = (
        <ReactGA.OutboundLink
          eventLabel={imageClickEvent}
          to={imageLink}
          target="_blank"
          rel="nofollow">
          {content}
        </ReactGA.OutboundLink>
      );
    }

    return content;
  };

  private imageClassName = () => {
    const { imagePadding } = this.props;
    const classes = ['d-none', 'd-md-block', 'overflow-hidden', 'text-center'];
    if (imagePadding) {
      classes.push(`p-${imagePadding}`);
    } else {
      classes.push('px-0');
    }
    return classes.join(' ');
  };
}

export default ImageSection;
