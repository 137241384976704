import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import React, { Component } from 'react';
import validator from 'validator';

import Avatar from '../utility/Avatar';
import TagAutocomplete from './TagAutocomplete';
import Tags from './Tags';

import './Account.scss';

interface Props {
  account: firebase.firestore.QueryDocumentSnapshot;
  allTags: firebase.firestore.QueryDocumentSnapshot[];
  filterTag: firebase.firestore.DocumentReference | null;
  onFilter: (tag: firebase.firestore.DocumentReference) => void;
  selected: boolean;
  toggleSelected: (account: firebase.firestore.DocumentSnapshot) => void;
}

interface State {
  accountData: firebase.firestore.DocumentSnapshot | null;
  userData: firebase.firestore.DocumentSnapshot | null;
}

class Account extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accountData: null,
      userData: null,
    };
  }

  public componentDidMount() {
    this.props.account.ref.onSnapshot((accountData) => this.setState({ accountData }));
    void this.loadData();
  }

  public render() {
    const { allTags, filterTag, selected } = this.props;
    const { accountData, userData } = this.state;
    if (!userData || !accountData) {
      return null;
    }
    const tags: firebase.firestore.DocumentReference[] =
      (accountData.get('tags') as firebase.firestore.DocumentReference[]) || [];
    const subscribed = !!userData.get('mailingListApproved');
    const classNames = [
      'Account',
      'border',
      'd-flex',
      'mb-4',
      'p-5',
      'position-relative',
      'rounded',
    ];
    let selectText;
    if (selected) {
      classNames.push('border-primary', 'selected');
      selectText = 'Unselect Account';
    } else {
      classNames.push('bg-white');
      selectText = 'Select Account';
    }

    return (
      <div className={classNames.join(' ')}>
        <div
          className="select border border-top-0 px-5 py-2 rounded-bottom text-center"
          onClick={this.select}>
          {selectText}
        </div>
        <div>
          <div className="avatar d-flex mb-3">
            <Avatar email={userData.id} size={75} />
            <div className="d-flex flex-column justify-content-center ml-3">
              <div>{userData.get('name')}</div>
              <div>
                {userData.id}
                {userData.get('isVerified') && (
                  <FontAwesomeIcon className="ml-1 text-success" icon={faCheckCircle} />
                )}
              </div>
              <div>
                <span className={subscribed ? 'text-success' : 'text-danger'}>
                  {subscribed ? 'Subscribed' : 'Unsubscribed'}
                </span>
              </div>
            </div>
          </div>
          {this.renderAddress()}
          <div>{userData.get('phone')}</div>
        </div>
        <div className="flex-grow-1 ml-4">
          <TagAutocomplete
            allTags={allTags}
            canCreate={true}
            className="d-flex flex-row-reverse mb-3"
            onSelect={this.tagSelect}
            tags={tags}
          />
          <Tags
            allTags={allTags}
            className="d-flex flex-row-reverse"
            filterTag={filterTag}
            onDelete={this.tagDelete}
            onFilter={this.props.onFilter}
            tags={tags.reverse()}
          />
        </div>
      </div>
    );
  }

  private renderAddress() {
    if (!this.state.userData) {
      return null;
    }

    const data = this.state.userData.data();
    if (!data) {
      return null;
    }

    const addressArray: string[] = [data.address as string, data.address2 as string];
    let cityStateZip = '';
    if (data.city) {
      cityStateZip += (data.city as string) + ', ';
    }
    if (data.state) {
      cityStateZip += (data.state as string) + ' ';
    }
    if (data.zip) {
      cityStateZip += data.zip as string;
    }
    addressArray.push(validator.trim(cityStateZip));

    return (
      <React.Fragment>
        {addressArray
          .filter((a) => !!a)
          .map((a) => (
            <div key={a}>{a}</div>
          ))}
      </React.Fragment>
    );
  }

  private tagDelete = async (tag: firebase.firestore.DocumentReference) => {
    const { account } = this.props;

    await account.ref.update({
      tags: firebase.firestore.FieldValue.arrayRemove(tag),
    });
  };

  private tagSelect = async (tag: firebase.firestore.DocumentReference) => {
    const { account } = this.props;

    await account.ref.update({
      tags: firebase.firestore.FieldValue.arrayUnion(tag),
    });
  };

  private select = () => {
    if (this.state.userData) {
      this.props.toggleSelected(this.state.userData);
    }
  };

  private loadData = async () => {
    const { account } = this.props;

    const ref: firebase.firestore.DocumentReference = account.get(
      'userData'
    ) as firebase.firestore.DocumentReference;
    const userData = await ref.get();

    this.setState({ userData });
  };
}

export default Account;
