import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Button } from 'reactstrap';

import blog from '../../../images/blog-sm.png';
import ImageSection from './ImageSection';

import './Blog.scss';

class Blog extends Component {
  public render() {
    const link = 'https://www.yourmomisright.com/';

    return (
      <ImageSection
        breakout={true}
        className="Blog px-4 py-5 p-md-6 text-center"
        color="green"
        image={blog}
        imageClickEvent="Visit YMIR"
        imageLink={link}
        imagePadding={6}
        imageSide="right">
        <h1 className="font-weight-bold mb-3 text-white">YMIR</h1>
        <h3 className="mb-4 text-uppercase">
          All Things
          <br />
          Skincare Blog
        </h3>
        <p className="mx-5 mb-4">
          Check out the sister site to UV & Tea, YMIR. Focused on skincare basics, we explore old
          and new practices to fight preventative aging.
        </p>
        <ReactGA.OutboundLink eventLabel="Visit YMIR" to={link} target="_blank" rel="nofollow">
          <Button color="info" className="px-5 py-3 text-uppercase">
            Learn More
          </Button>
        </ReactGA.OutboundLink>
      </ImageSection>
    );
  }
}

export default Blog;
