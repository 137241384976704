import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

interface State {
  user: firebase.User | null;
  verified: boolean;
}

class Verify extends Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      user: firebase.auth().currentUser,
      verified: false,
    };
  }

  public componentDidMount() {
    void this.verify();
  }

  public render() {
    const { verified } = this.state;

    if (verified) {
      return <Redirect to="/details" />;
    }

    return null;
  }

  private verify = async () => {
    const { user } = this.state;
    if (user && user.email) {
      await firebase.firestore().collection('users').doc(user.email.toLowerCase()).set(
        {
          isVerified: true,
        },
        {
          merge: true,
        }
      );
      this.setState({
        verified: true,
      });
    }
  };
}

export default Verify;
