import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import validator from 'validator';

interface Props {
  details?: firebase.firestore.DocumentSnapshot;
  email: string;
  user: firebase.User | null;
}

interface State extends Fields<string> {
  errors: Fields<string>;
  submitted: boolean;
  touched: Fields<boolean>;
}

interface Fields<T> {
  password: T;
}
type Field = keyof Fields<void>;

class Confirm extends Component<Props, State> {
  private input: React.RefObject<HTMLInputElement>;

  /* eslint-disable */
  private sortOrder: Fields<number> = {
    password: 1,
  };
  /* eslint-enable */

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      submitted: false,
      touched: {
        ...this.initializeFields(false),
      },
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  public render() {
    return (
      <div className="Confirm text-center">
        <h1>
          <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
        </h1>
        <h2 className="mb-3">Welcome to UV & Tea!</h2>
        <p className="mb-4">
          Your daily skincare routine is on it&apos;s way! Your next box will ship on:{' '}
          <b>{moment().add(1, 'month').date(1).format('LL')}</b>
        </p>
        {this.renderCreateAccount()}
      </div>
    );
  }

  private renderCreateAccount = () => {
    const { details, email, user } = this.props;
    const { password, submitted } = this.state;

    if (submitted) {
      return (
        <p>Account created! Please check for a confirmation email to validate your account.</p>
      );
    }

    if (user) {
      return (
        <p>
          To modify your subscription or account details, <Link to="/details">click here</Link>.
        </p>
      );
    }

    if (details && details.get('isVerified')) {
      return null;
    }

    return (
      <React.Fragment>
        <p className="mb-4">Create an account to manage your subscription and account details:</p>
        <Form onSubmit={this.submit}>
          <FormGroup>
            <Label for="email">Email Address</Label>
            <Input className="mb-3 rounded-0" disabled={true} id="email" value={email} />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              className="mb-3 rounded-0"
              id="password"
              invalid={this.isInvalid('password')}
              onChange={this.change('password')}
              placeholder="Password"
              type="password"
              valid={this.isValid('password')}
              value={password}
            />
          </FormGroup>
          <FormGroup>
            <FormText className="mt-0 mb-3" color="danger">
              {this.getCurrentError()}
            </FormText>
            <Button
              block={true}
              className="px-5"
              color="info"
              disabled={this.someInvalid()}
              size="lg">
              Create Account
            </Button>
          </FormGroup>
        </Form>
      </React.Fragment>
    );
  };

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [field]: event.target.value,
      touched: this.initializeFields(true),
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const { email } = this.props;
    const { password } = this.state;

    try {
      const credential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const url = new URL('/verify', window.location.href).href;
      if (credential.user) {
        await credential.user.sendEmailVerification({ url });
      }
      this.setState({ submitted: true });
    } catch (e) {
      const code = (e as firebase.FirebaseError).code;
      const errors = this.initializeFields('');
      if (code === 'auth/weak-password') {
        errors.password = 'Please use a longer password';
      }
      this.setState({
        errors,
      });
      return;
    }
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate fields
    this.getFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (_: Field, value: string): boolean => {
    return !validator.isEmpty(value);
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'password':
        return 'Please provide a password';
      default:
        return '';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      password: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };
}

export default Confirm;
