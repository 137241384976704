import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

import './Subscribe.scss';

class Subscribe extends Component {
  public render() {
    return (
      <div className="Subscribe mt-3 mx-0 mt-md-4 p-4">
        <h3>Monthly Subscription</h3>
        <p>
          A monthly supply of high quality sunscreen, lip treament, and anti-oxidant rich teas
          directly to your doorstep. Shipping is always free.
        </p>
        <p>
          Next box ships on <b>{moment().add(1, 'month').date(1).format('LL')}</b>
        </p>
        <Link to="/subscribe">
          <Button className="px-4" color="info" size="lg">
            Learn More
          </Button>
        </Link>
      </div>
    );
  }
}

export default Subscribe;
