import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, FormText, Input } from 'reactstrap';
import validator from 'validator';

import './Signin.scss';

interface Props extends RouteComponentProps {
  close: () => void;
  forgotPassword: () => void;
  signup: () => void;
}

interface State extends Fields<string> {
  errors: Fields<string>;
  submitted: boolean;
  touched: Fields<boolean>;
}

interface Fields<T> {
  email: T;
  password: T;
}
type Field = keyof Fields<void>;

class Signin extends Component<Props, State> {
  private input: React.RefObject<HTMLInputElement>;

  /* eslint-disable */
  private sortOrder: Fields<number> = {
    email: 1,
    password: 2,
  };
  /* eslint-enable */

  constructor(props: Props) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      submitted: false,
      touched: {
        ...this.initializeFields(false),
      },
    };
    this.input = React.createRef<HTMLInputElement>();
  }

  public componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  public render() {
    const { email, password } = this.state;

    return (
      <div className="Signin p-4 text-white">
        <h2 className="mb-4">Sign In</h2>
        <Form onSubmit={this.submit}>
          <FormGroup>
            <Input
              className="mb-3 rounded-0"
              id="email"
              innerRef={this.input}
              invalid={this.isInvalid('email')}
              onChange={this.change('email')}
              placeholder="Email Address"
              type="text"
              valid={this.isValid('email')}
              value={email}
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="mb-3 rounded-0"
              id="password"
              invalid={this.isInvalid('password')}
              onChange={this.change('password')}
              placeholder="Password"
              type="password"
              valid={this.isValid('password')}
              value={password}
            />
          </FormGroup>
          <FormGroup>
            <FormText className="mt-0" color="danger">
              {this.getCurrentError()}
            </FormText>
            <Button
              block={true}
              className="px-5"
              color="info"
              disabled={this.someInvalid()}
              size="lg">
              Sign in
            </Button>
          </FormGroup>
        </Form>
        <p className="text-center">OR</p>
        <Button block={true} className="px-5 mb-3" color="facebook" onClick={this.facebookLogin}>
          Continue with Facebook
        </Button>
        <Button block={true} className="px-5 mb-4" color="google" onClick={this.googleLogin}>
          Signin with Google
        </Button>
        <p className="text-center">
          <a className="text-white" href="#" onClick={this.create}>
            Don&apos;t have an account? Create one.
          </a>
        </p>
        <p className="text-center">
          <a className="text-white" href="#" onClick={this.forgotPassword}>
            Forgot password?
          </a>
        </p>
      </div>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [field]: event.target.value,
      touched: this.initializeFields(true),
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const { email, password } = this.state;

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      this.redirectToDetails();
    } catch (e) {
      this.setState({
        errors: {
          email: 'Email or password was invalid',
          password: 'Email or password was invalid',
        },
      });
      return;
    }
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');

    // Normalize fields
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
    });

    // Validate fields
    this.getFields().forEach((field) => {
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });

    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      default:
        return !validator.isEmpty(value);
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'email':
        return 'Please provide a valid email address';
      case 'password':
        return 'Please provide a password';
      default:
        return '';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      email: val,
      password: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };

  private facebookLogin = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      await firebase.auth().signInWithPopup(provider);
      await new Promise((res) => setTimeout(res, 5000));
      this.redirectToDetails();
    } catch (e) {
      return;
    }
  };

  private googleLogin = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const credential = await firebase.auth().signInWithPopup(provider);
      if (credential.user && credential.user.email) {
        await firebase.firestore().collection('users').doc(credential.user.email.toLowerCase()).set(
          {
            isVerified: true,
          },
          {
            merge: true,
          }
        );
      }
      this.redirectToDetails();
    } catch (e) {
      return;
    }
  };

  private create = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.signup();
  };

  private forgotPassword = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.forgotPassword();
  };

  private redirectToDetails = () => {
    this.props.history.push('/details');
    this.props.close();
  };
}

export default withRouter(Signin);
