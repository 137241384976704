import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import animateScrollTo from 'animated-scroll-to';

import Admin from './admin/Admin';
import Details from './details/Details';
import NotFound from './error/NotFound';
import Footer from './footer/Footer';
import Header from './header/Header';
import Home from './home/Home';
import Privacy from './privacy/Privacy';
import Subscribe from './subscribe/Subscribe';
import Verify from './verify/Verify';

import './App.scss';

interface State {
  isAdmin: boolean;
  loading: boolean;
  user: firebase.User | null;
}

class App extends Component<any, State> {
  private _componentCache: Map<React.ComponentType, React.ComponentType> = new Map<
    React.ComponentType,
    React.ComponentType
  >();

  constructor(props: any) {
    super(props);

    this.state = {
      isAdmin: false,
      loading: true,
      user: null,
    };
  }

  public componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setState({ user });

      // Update all user details and attributes
      let isAdmin = false;
      if (user && user.email) {
        const result = await user.getIdTokenResult();
        isAdmin = !!result.claims.isAdmin;

        if (!user.emailVerified) {
          await user.reload();
        }
        try {
          await firebase.firestore().collection('users').doc(user.email.toLowerCase()).get();
        } catch (e) {
          await user.getIdToken(true);
        }
      }
      this.setState({ isAdmin, loading: false, user });
    });
  }

  public render() {
    const { isAdmin, loading, user } = this.state;

    if (loading) {
      return <div className="App min-vh-100" />;
    }

    return (
      <Router>
        <StripeProvider apiKey="pk_live_IggTHy5bXyvhXiDbMkP5bSPn">
          <div className="App d-flex flex-column min-vh-100 px-3 px-md-0">
            <Header logOut={this.logOut} user={user} />
            <main className="flex-grow-1">
              <Switch>
                <Route exact={true} path="/" component={this.scrollToTop(Home)} />
                <Route exact={true} path="/privacy" component={this.scrollToTop(Privacy)} />
                <Route exact={true} path="/subscribe" component={this.scrollToTop(Subscribe)} />
                <PrivateRoute
                  component={Verify}
                  exact={true}
                  isAuthenticated={!!user && !!user.email}
                  path="/verify"
                />
                <PrivateRoute
                  component={this.scrollToTop(Details)}
                  exact={true}
                  isAuthenticated={!!user}
                  path="/details"
                />
                <PrivateRoute
                  component={Admin}
                  exact={true}
                  isAuthenticated={!!user && isAdmin}
                  path="/admin"
                />
                <Route component={this.scrollToTop(NotFound)} />
              </Switch>
            </main>
            <Footer />
          </div>
        </StripeProvider>
      </Router>
    );
  }

  private scrollToTop = (WrappedComponent: React.ComponentType) => {
    if (!this._componentCache.has(WrappedComponent)) {
      const component = class ScrollToTopComponent extends Component {
        public componentDidMount() {
          void animateScrollTo(0);
        }

        public render() {
          return <WrappedComponent />;
        }
      };
      this._componentCache.set(WrappedComponent, component);
    }
    return this._componentCache.get(WrappedComponent);
  };

  private logOut = () => {
    const history = createBrowserHistory();
    history.push('/');
    this.forceUpdate();
    void firebase
      .auth()
      .signOut()
      .then(() => {
        ReactGA.event({ action: 'Sign out', category: 'User' });
      });
  };
}

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

class PrivateRoute extends Route<PrivateRouteProps> {
  public render() {
    const { isAuthenticated, ...props } = this.props;
    if (!isAuthenticated) {
      return <Route component={NotFound} />;
    } else {
      return <Route {...props} />;
    }
  }
}

export default App;
