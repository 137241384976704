import { faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';

class NotFound extends Component {
  public render() {
    return (
      <Container className="NotFound pt-8 text-center text-white">
        <Row>
          <Col md={12}>
            <h1 className="mb-4">Oops!</h1>
            <h2 className="mb-3">404 Not Found</h2>
            <div className="error-details mb-3">
              Sorry, an error has occured, and the requested page was not found!
            </div>
            <div>
              <Link to="/">
                <Button color="primary" className="mr-2">
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                  Take Me Home
                </Button>
              </Link>
              <a href="mailto:support@uvtea.com">
                <Button color="secondary" className="ml-2">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Contact Support
                </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NotFound;
