import React, { Component } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface Props {
  changeLimit: (limit: number) => void;
  className?: string;
  limit: number;
}

interface State {
  open: boolean;
}

class Pagination extends Component<Props, State> {
  private limits = [5, 10, 25, 50, 100];

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public render() {
    const { className, limit } = this.props;
    const { open } = this.state;

    if (limit === 0) {
      return null;
    }

    return (
      <div className={`Limit ${className || ''}`}>
        <div className="d-flex align-items-center">
          <div className="mr-2">Num per page:</div>
          <Dropdown isOpen={open} toggle={this.toggle}>
            <DropdownToggle caret={true}>{limit}</DropdownToggle>
            <DropdownMenu>
              {this.limits.map((l) => (
                <DropdownItem key={l} onClick={this.changeLimit(l)}>
                  {l}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    );
  }

  private changeLimit = (limit: number) => () => {
    this.props.changeLimit(limit);
  };

  private toggle = () => this.setState({ open: !this.state.open });
}

export default Pagination;
