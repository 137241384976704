import * as firebase from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from './components/App';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './custom.scss';
import './index.scss';

let config = {
  apiKey: 'AIzaSyDoO4f9b7SLjM35LmueiMnPQuyjNjSjYAk',
  authDomain: 'uvntea.firebaseapp.com',
  databaseURL: 'https://uvntea.firebaseio.com',
  messagingSenderId: '718703734017',
  projectId: 'uvntea',
  storageBucket: 'uvntea.appspot.com',
};
firebase.initializeApp(config);

ReactGA.initialize('UA-131039079-3', { debug: true });

ReactDOM.render(<App />, document.getElementById('root'));
