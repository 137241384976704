import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { Button, CustomInput, Form, FormText, Input } from 'reactstrap';
import validator from 'validator';

import Followup from '../../modal/Followup';

import './MailingList.scss';

interface State extends Fields<string> {
  errors: Fields<string>;
  followupOpen: boolean;
  submitted: boolean;
  success: string;
  touched: Fields<boolean>;
}

interface Fields<T> {
  email: T;
  privacyAccepted: T;
}
type Field = keyof Fields<void>;

class MailingList extends Component<any, State> {
  /* eslint-disable */
  private sortOrder: Fields<number> = {
    email: 1,
    privacyAccepted: 2,
  };
  /* eslint-enable */

  constructor(props: any) {
    super(props);
    this.state = {
      ...this.initializeFields(''),
      errors: {
        ...this.initializeFields(''),
      },
      followupOpen: false,
      submitted: false,
      success: '',
      touched: {
        ...this.initializeFields(false),
      },
    };
  }

  public render() {
    const { email, followupOpen, privacyAccepted, success } = this.state;

    return (
      <div className="MailingList mt-3 mx-0 m-md-0">
        <Form onSubmit={this.submit}>
          <Input
            className="mb-3 rounded-0"
            id="email"
            invalid={this.isInvalid('email')}
            onChange={this.change('email')}
            placeholder="Email"
            type="text"
            valid={this.isValid('email')}
            value={email}
          />
          <CustomInput
            checked={privacyAccepted === 'true'}
            className="mb-3"
            id="privacyAccepted"
            invalid={this.isInvalid('privacyAccepted')}
            onChange={this.change('privacyAccepted')}
            type="checkbox"
            valid={this.isValid('privacyAccepted')}>
            I agree to the
            <Link className="ml-1" to="/privacy">
              Privacy Policy
            </Link>
          </CustomInput>
          <Button className="px-4" color="info" disabled={this.someInvalid()} size="lg">
            Subscribe to Mailing List
          </Button>
          <FormText color="danger">{this.getCurrentError()}</FormText>
          <FormText color="muted">{success}</FormText>
        </Form>
        <Followup close={this.finish} email={email} isOpen={followupOpen} />
      </div>
    );
  }

  private change = (field: Field) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { touched } = this.state;
    const value =
      event.target.type === 'checkbox' ? event.target.checked.toString() : event.target.value;

    this.setState({
      ...this.state,
      [field]: value,
      success: '',
      touched: { ...touched, [field]: true },
    });
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = await this.validate();

    if (!isValid) {
      return;
    }

    const { email } = this.state;

    try {
      const auth = firebase.auth();
      const isVerified = !!(
        auth.currentUser &&
        auth.currentUser.email &&
        auth.currentUser.email.toLowerCase() === email.toLowerCase() &&
        auth.currentUser.emailVerified
      );

      await firebase.firestore().collection('users').doc(email.toLowerCase()).set(
        {
          isVerified,
          mailingListApproved: true,
        },
        {
          merge: true,
        }
      );

      ReactGA.event({ action: 'Subscribe to mailing list', category: 'User' });

      // Default to opening the followup, but if we can read the address and it exists cancel out
      let followupOpen = true;
      try {
        const record = await firebase
          .firestore()
          .collection('users')
          .doc(email.toLowerCase())
          .get();
        followupOpen = !record.get('address');
        if (!followupOpen) {
          this.finish();
        }
      } catch (e) {
        console.error(e);
      }

      this.setState({
        followupOpen,
      });
    } catch (e) {
      console.error(e);
    }

    this.setState({
      errors: this.initializeFields(''),
      submitted: false,
      success: `Thanks for subscribing!`,
      touched: this.initializeFields(false),
    });
  };

  private finish = () => {
    this.setState({
      ...this.initializeFields(''),
      followupOpen: false,
    });
  };

  private validate = async (): Promise<boolean> => {
    const errors = this.initializeFields('');
    const fields = this.initializeFields('');
    this.getFields().forEach((field) => {
      fields[field] = validator.trim(this.state[field]);
      const valid = this.isFieldValid(field, fields[field]);
      if (!valid) {
        errors[field] = this.getError(field);
      }
    });
    return new Promise((res) => {
      this.setState(
        { ...fields, errors, submitted: true, success: '', touched: this.initializeFields(false) },
        () => {
          res(!this.getCurrentError());
        }
      );
    });
  };

  private isFieldValid = (field: Field, value: string): boolean => {
    switch (field) {
      case 'email':
        return validator.isEmail(value);
      case 'privacyAccepted':
        return value === 'true';
    }
  };

  private getError = (field: Field) => {
    switch (field) {
      case 'email':
        return 'Please provide a valid email address';
      case 'privacyAccepted':
        return 'Please accept our privacy policy';
    }
  };

  private getCurrentError = (): string => {
    const { errors, touched } = this.state;
    return (
      this.getFields()
        .map((field) => !touched[field] && errors[field])
        .find((error) => !!error) || ''
    );
  };

  private initializeFields = <T extends any>(val: T): Fields<T> => {
    return {
      email: val,
      privacyAccepted: val,
    };
  };

  private getFields = (): Field[] => {
    return (Object.keys(this.initializeFields('')) as Field[]).sort((f1, f2) => {
      return this.sortOrder[f1] - this.sortOrder[f2];
    });
  };

  private isValid = (field: Field): boolean => {
    const { errors, submitted } = this.state;
    return submitted && !errors[field];
  };

  private isInvalid = (field: Field): boolean => {
    const { errors, submitted, touched } = this.state;
    return submitted && !touched[field] && !!errors[field];
  };

  private someInvalid = (): boolean => {
    return this.getFields().some(this.isInvalid);
  };
}

export default MailingList;
