import * as firebase from 'firebase/app';
import 'firebase/auth';
import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import Avatar from '../utility/Avatar';

import './User.scss';

interface Props extends RouteComponentProps {
  logOut: () => void;
  user: firebase.User;
}

interface State {
  isAdmin: boolean;
}

class User extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAdmin: false,
    };
  }

  public componentDidMount() {
    void this.loadData();
  }

  public render() {
    const { user } = this.props;
    const { isAdmin } = this.state;

    let details = (
      <Link className="text-white" to="/details">
        Edit Details
      </Link>
    );
    if (isAdmin) {
      details = (
        <Link className="text-white" to="/admin">
          Admin
        </Link>
      );
    }

    return (
      <div className="User d-flex flex-row-reverse text-right">
        <div className="avatar d-none d-md-block ml-4">
          <Avatar user={user} size={50} />
        </div>
        <div className="d-flex flex-column justify-content-center text-white-50">
          <div>{user.email}</div>
          <div className="text-white">
            {details}
            {' | '}
            <a className="text-white" href="#" onClick={this.logOut}>
              Sign Out
            </a>
          </div>
        </div>
      </div>
    );
  }

  private loadData = async () => {
    const { user } = this.props;

    let isAdmin = false;
    if (user) {
      const result = await user.getIdTokenResult();
      isAdmin = !!result.claims.isAdmin;
    }
    this.setState({ isAdmin });
  };

  private logOut = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.props.history.push('/');
    this.props.logOut();
  };
}

export default withRouter(User);
