import * as firebase from 'firebase/app';
import 'firebase/functions';
import moment from 'moment';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import stripe from 'stripe';

import amex from '../../images/cc/amex.png';
import dinersclub from '../../images/cc/dinersclub.png';
import discover from '../../images/cc/discover.png';
import jcb from '../../images/cc/jcb.png';
import mastercard from '../../images/cc/mastercard.png';
import unknown from '../../images/cc/unknown.png';
import visa from '../../images/cc/visa.png';
import './Subscription.scss';

interface Props {
  customer?: stripe.Customer;
  hidden: boolean;
  loadData: () => Promise<void>;
}

interface State {
  cancelling: boolean;
  subscription?: stripe.Subscription;
}

class Subscription extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cancelling: false,
      subscription: undefined,
    };
  }

  public componentDidMount() {
    this.loadSubscription(this.props.customer);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.customer !== this.props.customer) {
      this.loadSubscription(this.props.customer);
    }
  }

  public render() {
    const { customer, hidden } = this.props;
    const { cancelling, subscription } = this.state;

    if (hidden) {
      return null;
    }

    if (
      !cancelling &&
      customer &&
      subscription &&
      subscription.plan &&
      subscription.plan.amount &&
      subscription.tax_percent
    ) {
      const card = this.getCard(customer);

      if (card) {
        const cost = (
          (subscription.plan.amount * (1 + subscription.tax_percent / 100)) /
          100
        ).toFixed(2);

        return (
          <div className="subscription">
            <p>
              {subscription.plan.nickname} Plan - <b>${cost}</b> (
              <a href="#" onClick={this.cancelSubscription(subscription)}>
                cancel subscription
              </a>
              )
            </p>
            <p>
              Next Billing Date:{' '}
              <b>{moment(subscription.current_period_end * 1000).format('LL')}</b>
            </p>
            <div className="d-flex flex-row">
              <div className="credit-card-image mr-3">{this.getCardImage(card)}</div>
              <div className="credit-card-details">
                <p>{card.name}</p>
                <p>{('X'.repeat(4) + '-').repeat(3) + card.last4}</p>
                <p className="mb-0">
                  Exp Date: {card.exp_month}/{card.exp_year}
                </p>
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="subscription">
        Unsubscribed (<Link to="/subscribe">subscribe</Link>)
      </div>
    );
  }

  private getCard = (customer: stripe.Customer): stripe.Card | null => {
    if (customer.default_source) {
      if (typeof customer.default_source === 'string') {
        if (customer.sources) {
          const card = customer.sources.data
            .filter<stripe.Card>((source): source is stripe.Card => source.object === 'card')
            .find((card) => card.id === customer.default_source);
          if (card) {
            return card;
          }
        }
      } else if (customer.default_source.object === 'card') {
        return customer.default_source;
      }
    }
    return null;
  };

  private getCardImage = (card: stripe.Card) => {
    switch (card.brand) {
      case 'American Express':
        return <img src={amex} alt={card.brand} />;
      case 'Diners Club':
        return <img src={dinersclub} alt={card.brand} />;
      case 'Discover':
        return <img src={discover} alt={card.brand} />;
      case 'JCB':
        return <img src={jcb} alt={card.brand} />;
      case 'MasterCard':
        return <img src={mastercard} alt={card.brand} />;
      case 'Visa':
        return <img src={visa} alt={card.brand} />;
      case 'Unknown':
      default:
        return <img src={unknown} alt="Credit Card" />;
    }
  };

  private loadSubscription = (customer?: stripe.Customer) => {
    if (customer && customer.subscriptions && customer.subscriptions.data.length > 0) {
      this.setState({
        subscription: customer.subscriptions.data[0],
      });
    }
  };

  private cancelSubscription = (subscription: stripe.Subscription) => async () => {
    this.setState({
      cancelling: true,
    });

    const removeSubscription = firebase.functions().httpsCallable('removeSubscription');
    const result = await removeSubscription({
      subscription: subscription.id,
    });

    ReactGA.event({
      action: 'Unsubscribed',
      category: 'Subscription',
    });

    await this.props.loadData();

    this.setState({
      cancelling: false,
      subscription: result.data as stripe.Subscription,
    });
  };
}

export default Subscription;
