import React, { Component } from 'react';
import { CardDeck, Container, Nav, NavItem, NavLink } from 'reactstrap';

import lipbalm from '../../../images/promo-lipbalm.png';
import sunscreen from '../../../images/promo-sunscreen.png';
import tea from '../../../images/promo-tea.png';

import { Color } from '../../../types';
import PromoCard from './PromoCard';

import './Promo.scss';

interface State {
  activeTab: Tab;
}
type Tab = 'sunscreen' | 'lip' | 'tea';

class Promo extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: 'sunscreen',
    };
  }

  public render() {
    const { activeTab } = this.state;
    return (
      <Container className="Promo mb-3 mb-md-6">
        <Nav className={'d-md-none ' + this.getColor(activeTab)} justified={true} tabs={true}>
          <NavItem>
            <NavLink
              className={this.getColor('sunscreen')}
              active={activeTab === 'sunscreen'}
              onClick={this.setActive('sunscreen')}>
              Sunscreen
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.getColor('lip')}
              active={activeTab === 'lip'}
              onClick={this.setActive('lip')}>
              Lip Balm
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.getColor('tea')}
              active={activeTab === 'tea'}
              onClick={this.setActive('tea')}>
              Tea
            </NavLink>
          </NavItem>
        </Nav>
        <CardDeck className={'promo-cards ' + this.getColor(activeTab)}>
          <PromoCard
            active={activeTab === 'sunscreen'}
            color={this.getColor('sunscreen')}
            image={sunscreen}
            title="Sunscreen">
            Our greatest ally in preventative aging is sunscreen. We look for UV protectants that
            are long-lasting, dry clear and are non-comedogenic.
          </PromoCard>
          <PromoCard
            active={activeTab === 'lip'}
            color={this.getColor('lip')}
            image={lipbalm}
            title="Lip Treatments">
            Daytime application of SPF lip balm helps to preserve the vitality of your lips, and
            evening lip treatments add longevity to their feel and appearance.
          </PromoCard>
          <PromoCard
            active={activeTab === 'tea'}
            color={this.getColor('tea')}
            image={tea}
            title="Antioxidant Rich Tea">
            As a supplement to your diet and skincare routine, tea can:
            <ul>
              <li>Conserve pore size</li>
              <li>Prevent acne</li>
              <li>Soothe your skin</li>
            </ul>
          </PromoCard>
        </CardDeck>
      </Container>
    );
  }

  private setActive = (activeTab: Tab) => () => {
    this.setState({ activeTab });
  };

  private getColor = (tab: Tab): Color => {
    switch (tab) {
      case 'sunscreen':
        return 'green';
      case 'lip':
        return 'yellow';
      case 'tea':
        return 'blue';
    }
  };
}

export default Promo;
